import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import {
	API_EXCHANGE_MISSION,
	API_MANAGE_LIST_LEVELS_LANGUAGES,
	GET_MISSION_BY_SCENE_SUB_WORLD,
	GET_SCENE_SUBWORLD_BY_SUBWORLD,
	GET_SUB_WORLD_BY_WORLD,
	GET_WORLDS_BY_LEVEL,
} from '../../../../utils/constantsAdmin'
import {
	onInputChangeDefault,
	returnProcessUrl,
} from '../../../../utils/general_functions_forms'
import SaveButton from '../../SaveButton'
import { LoadingIcon } from '../../LoadingIcon'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import { ManageStorageGameLanguage } from '../../../../utils/manage_local_storage'

export const FormExchangeMission = ({ objMission, setRefreshNpcsScene }) => {
	const { t } = useTranslation()

	// CONTEXT
	const { axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)
	// MODAL
	const { openModal } = useContext(ModalContext)
	// LOCAL STORAGE
	const { getGameLanguage } = ManageStorageGameLanguage()

	// VARS
	const [levelLanguage, setLevelLanguage] = useState('')
	const [world, setWorld] = useState('')
	const [subWorld, setSubWorld] = useState('')
	const [sceneSubWorld, setSceneSubWorld] = useState('')
	const [npcSceneSubWorld, setNpcSceneSubWorld] = useState('')

	// LISTS
	const [listLevelLanguages, setListLevelLanguages] = useState({})
	const [listWorlds, setListWorlds] = useState({})
	const [listSubworlds, setListSubworlds] = useState({})
	const [listSceneSubWorlds, setListSceneSubWorlds] = useState({})
	const [listNpcSceneSubWorlds, setListNpcSceneSubWorlds] = useState({})

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	useEffect(() => {
		let idGameLanguage = JSON.parse(getGameLanguage())
		fetchOptionsLevelLanguages(
			axiosSupreme,
			API_MANAGE_LIST_LEVELS_LANGUAGES +
				idGameLanguage.id_game_language +
				'/',
			setListLevelLanguages
		)
	}, [])

	const onChangeLevelLanguage = (value) => {
		setLevelLanguage(value)
		setWorld('')
		setSubWorld('')
		setSceneSubWorld('')
		setListWorlds({})
		setListSubworlds({})
		setListSceneSubWorlds({})
		setListNpcSceneSubWorlds({})
		if (value) {
			fetchOptionsWorlds(
				axiosSupreme,
				GET_WORLDS_BY_LEVEL + value + '/',
				setListWorlds
			)
		}
	}

	const onChangeWorld = (value) => {
		setWorld(value)
		setSubWorld('')
		setSceneSubWorld('')
		setListSubworlds({})
		setListSceneSubWorlds({})
		setListNpcSceneSubWorlds({})
		if (value) {
			fetchOptionsSubWorlds(
				axiosSupreme,
				GET_SUB_WORLD_BY_WORLD + value + '/',
				setListSubworlds
			)
		}
	}

	const onChangeSubWorld = (value) => {
		setSubWorld(value)
		setSceneSubWorld('')
		setListSceneSubWorlds({})
		setListNpcSceneSubWorlds({})
		if (value) {
			fetchOptionsSceneSubWorlds(
				axiosSupreme,
				GET_SCENE_SUBWORLD_BY_SUBWORLD + value + '/',
				setListSceneSubWorlds
			)
		}
	}

	const onChangeSceneSubWorld = (value) => {
		setSceneSubWorld(value)
		setListNpcSceneSubWorlds({})
		if (value) {
			fetchOptionsMissionBySceneSubWorlds(
				axiosSupreme,
				GET_MISSION_BY_SCENE_SUB_WORLD + value + '/',
				setListNpcSceneSubWorlds
			)
		}
	}

	const onSubmitExchange = async (e) => {
		try {
			e.preventDefault()
			setStateLoading(true)
			// MAKE DICT
			let dataSend = new FormData()
			dataSend.append(
				'sub_world_scene_npc_appearance',
				objMission.sub_world_scene_npc_appearance
			)
			dataSend.append(
				'new_sub_world_scene_npc_appearance',
				npcSceneSubWorld
			)
			await axiosSupreme(
				'patch',
				API_EXCHANGE_MISSION + objMission.id_mission + '/',
				dataSend
			)
			setRefreshNpcsScene((prev) => !prev)
			setTypeMessage('success')
			setMessage(t('common.modified'))
			showAlert()
			openModal()
		} catch (error) {
			setTypeMessage('error')
			setMessage(error)
		} finally {
			setStateLoading(false)
		}
	}

	return (
		<form action='' onSubmit={onSubmitExchange}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_content_missions.lang_level'
					)}
				</label>
				<select
					name='level_language'
					className='admin__container__inputs__in'
					id='level_language'
					placeholder={t(
						'pages.editor.components.form_content_missions.placeholder_stars'
					)}
					autoComplete='off'
					value={levelLanguage}
					onChange={(event) =>
						onChangeLevelLanguage(event.target.value)
					}
					required>
					<option key='' value=''>
						{t(
							'pages.editor.components.form_content_missions.select'
						)}
					</option>
					{Object.keys(listLevelLanguages).map((key) => (
						<option key={key} value={key}>
							{listLevelLanguages[key].name}
						</option>
					))}
				</select>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_content_missions.world')}
				</label>
				<select
					name='level_language'
					className='admin__container__inputs__in'
					id='level_language'
					placeholder={t(
						'pages.editor.components.form_content_missions.placeholder_stars'
					)}
					autoComplete='off'
					value={world}
					onChange={(event) => onChangeWorld(event.target.value)}
					required>
					<option key='' value=''>
						{t(
							'pages.editor.components.form_content_missions.select'
						)}
					</option>
					{Object.keys(listWorlds).map((key) => (
						<option key={key} value={key}>
							{listWorlds[key].name}
						</option>
					))}
				</select>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_content_missions.sub_world'
					)}
				</label>
				<select
					name='subworld'
					className='admin__container__inputs__in'
					id='subworld'
					placeholder={t(
						'pages.editor.components.form_content_missions.placeholder_stars'
					)}
					autoComplete='off'
					value={subWorld}
					onChange={(event) => onChangeSubWorld(event.target.value)}
					required>
					<option key='' value=''>
						{t(
							'pages.editor.components.form_content_missions.select'
						)}
					</option>
					{Object.keys(listSubworlds).map((key) => (
						<option key={key} value={key}>
							{listSubworlds[key].name}
						</option>
					))}
				</select>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_content_missions.sub_world_escene'
					)}
				</label>
				<select
					name='scene_subworld'
					className='admin__container__inputs__in'
					id='scene_subworld'
					placeholder={t(
						'pages.editor.components.form_content_missions.placeholder_stars'
					)}
					autoComplete='off'
					value={sceneSubWorld}
					onChange={(event) =>
						onChangeSceneSubWorld(event.target.value)
					}
					required>
					<option key='' value=''>
						{t(
							'pages.editor.components.form_content_missions.select'
						)}
					</option>
					{Object.keys(listSceneSubWorlds).map((key) => (
						<option key={key} value={key}>
							{listSceneSubWorlds[key].name}
						</option>
					))}
				</select>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_content_missions.mission_sub_world_escene'
					)}
				</label>
				<select
					name='scene_subworld'
					className='admin__container__inputs__in'
					id='scene_subworld'
					placeholder={t(
						'pages.editor.components.form_content_missions.placeholder_stars'
					)}
					autoComplete='off'
					value={npcSceneSubWorld}
					onChange={(event) =>
						onInputChangeDefault(event, setNpcSceneSubWorld)
					}
					required>
					<option key='' value=''>
						{t(
							'pages.editor.components.form_content_missions.select'
						)}
					</option>
					{Object.keys(listNpcSceneSubWorlds).map((key) => (
						<option key={key} value={key}>
							{listNpcSceneSubWorlds[key].name}
						</option>
					))}
				</select>
			</div>

			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton />
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}

const fetchOptionsLevelLanguages = async (axiosSupreme, url, setList) => {
	const fetchData = async (url) => {
		const processedUrl = returnProcessUrl(url)
		const resultData = await axiosSupreme('get', processedUrl, undefined)

		setList((prevData) => ({
			...prevData,
			...resultData.results.reduce((acc, dataItem) => {
				acc[dataItem.id_level_language] = { name: dataItem.name }
				return acc
			}, {}),
		}))
		if (resultData.next) {
			await fetchData(resultData.next)
		}
	}
	await fetchData(url)
}

const fetchOptionsWorlds = async (axiosSupreme, url, setList) => {
	const fetchData = async (url) => {
		const processedUrl = returnProcessUrl(url)
		const resultData = await axiosSupreme('get', processedUrl, undefined)
		setList((prevData) => ({
			...prevData,
			...resultData.reduce((acc, dataItem) => {
				acc[dataItem.id_world] = { name: dataItem.name }
				return acc
			}, {}),
		}))
		if (resultData.next) {
			await fetchData(resultData.next)
		}
	}
	await fetchData(url)
}

const fetchOptionsSubWorlds = async (axiosSupreme, url, setList) => {
	const fetchData = async (url) => {
		const processedUrl = returnProcessUrl(url)
		const resultData = await axiosSupreme('get', processedUrl, undefined)

		setList((prevData) => ({
			...prevData,
			...resultData.results.reduce((acc, dataItem) => {
				acc[dataItem.id_sub_world] = { name: dataItem.name }
				return acc
			}, {}),
		}))
		if (resultData.next) {
			await fetchData(resultData.next)
		}
	}
	await fetchData(url)
}

const fetchOptionsSceneSubWorlds = async (axiosSupreme, url, setList) => {
	const fetchData = async (url) => {
		const processedUrl = returnProcessUrl(url)
		const resultData = await axiosSupreme('get', processedUrl, undefined)

		setList((prevData) => ({
			...prevData,
			...resultData.results.reduce((acc, dataItem) => {
				acc[dataItem.id_scene_sub_world] = { name: dataItem.name }
				return acc
			}, {}),
		}))

		if (resultData.next) {
			await fetchData(resultData.next)
		}
	}

	await fetchData(url)
}

const fetchOptionsMissionBySceneSubWorlds = async (
	axiosSupreme,
	url,
	setList
) => {
	const fetchData = async (url) => {
		const processedUrl = returnProcessUrl(url)
		const resultData = await axiosSupreme('get', processedUrl, undefined)

		setList((prevData) => ({
			...prevData,
			...resultData.results.reduce((acc, dataItem) => {
				const mission =
					dataItem.challenge.category === 'l'
						? 'Clase'
						: dataItem.challenge.category === 'o'
						? 'Objeto Perdido'
						: dataItem.challenge.category === 'g'
						? 'Juego'
						: dataItem.challenge.category === 'ee'
						? 'Huevo de Pascua'
						: 'No reconocido'

				acc[dataItem.sub_world_scene_npc_appearance] = {
					name: `${dataItem.npc.name} - ${mission}`,
				}

				return acc
			}, {}),
		}))
	}

	await fetchData(url)
}
