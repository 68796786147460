import React, { useContext, useEffect, useState } from 'react'
import SaveButton from '../../../SaveButton'
import AlertContext from '../../../../../context/editor/AlertContext'
import ModalContext from '../../../../../context/editor/ModalContext'
import { API_MANAGE_NPC_POSES } from '../../../../../utils/constantsAdmin'
import {
	onInputChangeDefault,
	processErrors,
} from '../../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../../LoadingIcon'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const FormPoses = ({
	stateObjectPose,
	setStateObjectPose,
	setRefreshPosesNpc,
}) => {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [name, setName] = useState('')
	const [description, setDescription] = useState('')

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		setDescription('')
	}

	// SET LEVEL WHEN UPDATE
	useEffect(() => {
		if (stateObjectPose !== undefined) {
			setName(stateObjectPose.name)
			setDescription(stateObjectPose.description)
		} else {
			setName('')
			setDescription('')
		}
	}, [stateObjectPose])

	// SUBMIT FORM POSES
	const onSubmitPose = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('name', event.target.elements.name.value)
		formData.append('description', event.target.elements.description.value)

		if (stateObjectPose === undefined) {
			// SAVE
			managePoses(API_MANAGE_NPC_POSES, formData, 'add')
		} else {
			// UPDATE
			managePoses(
				API_MANAGE_NPC_POSES + stateObjectPose.id_npc_pose + '/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE POSES
	const managePoses = async (url, obj, action) => {
		try {
			// PROCESS DATA
			let result_data =
				action === 'add'
					? await axiosSupreme('post', url, obj)
					: await axiosSupreme('patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshPosesNpc((prev) => !prev)
			if (action === 'update') setStateObjectPose(result_data)
			openModal()
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	return (
		<>
			<form onSubmit={onSubmitPose}>
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_poses.pose_name')}
					</label>
					<input
						maxLength='45'
						name='name'
						className='admin__container__inputs__in'
						id='name'
						type='text'
						placeholder={t(
							'pages.editor.components.form_poses.pose_name_placeholder'
						)}
						autoComplete='off'
						value={name}
						onChange={(event) =>
							onInputChangeDefault(event, setName)
						}
						required></input>
				</div>
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_poses.description_and_placeholder'
						)}
					</label>
					<textarea
						maxLength='500'
						name='description'
						className='admin__container__inputs__in'
						id='description'
						type='text-area'
						placeholder={t(
							'pages.editor.components.form_poses.description_and_placeholder'
						)}
						autoComplete='off'
						value={description}
						onChange={(event) =>
							onInputChangeDefault(event, setDescription)
						}></textarea>
				</div>
				{stateLoading === false ? (
					<div className='admin__container__boxBtn'>
						<SaveButton></SaveButton>
					</div>
				) : (
					<LoadingIcon />
				)}
			</form>
		</>
	)
}
