import { useContext, useEffect, useState } from 'react'
import {
	API_MANAGE_DOORS,
	API_MANAGE_PATH_DOORS,
	API_MANAGE_PATH_SUBWORLD_DOORS,
	API_MANAGE_SCENE_SUB_WORLDS,
	API_MANAGE_SCENE_WORLDS,
	API_MANAGE_SUBWORLD_DOORS,
	GET_DOORS_BY_SCENE,
	GET_SCENE_SUBWORLD_BY_SUBWORLD,
	GET_SCENE_WORLD_BY_WORLD,
	GET_SUBWORLD_DOORS_BY_SCENE,
	GET_SUB_WORLD_BY_GAME_LANGUAGE,
	GET_WORLDS_BY_GAME_LANGUAGE,
} from '../../../../utils/constantsAdmin'
import SaveButton from '../../SaveButton'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import { ManageStorageAdminDesigner } from '../../../../utils/local_storage'
import {
	onInputChangeDefault,
	processErrors,
	returnProcessUrl,
} from '../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'
import { ManageStorageGameLanguage } from '../../../../utils/manage_local_storage'

export const FormDestinationDoor = ({
	setStateObjectDoor,
	objectWorldDoor,
}) => {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// LOCAL STORAGE
	const { getGameLanguage } = ManageStorageGameLanguage()
	// LOCAL STORAGE
	const { gettypeContextSpace } = ManageStorageAdminDesigner()

	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES LIST FOR SELECTS
	const optionsWorldOrSubworld = ['Mundo', 'Submundo']
	const [optionsObjectWorldOrSubworld, setOptionsObjectWorldOrSubworld] =
		useState({})
	const [optionsScenes, setOptionsScenes] = useState({})
	const [optionsDoors, setOptionsDoors] = useState({})

	// VALUES FORM
	const [worldOrSubworld, setWorldOrSubworld] = useState(undefined)
	const [objectWorldOrSubworld, setObjectWorldOrSubworld] =
		useState(undefined)
	const [objectScene, setObjectScene] = useState(undefined)
	const [objectDoor, setObjectDoor] = useState(undefined)

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// SET LEVEL WHEN UPDATE
	useEffect(() => {
		if (objectWorldDoor !== undefined) {
			setDestinationValues()
		} else {
			clearFields()
		}
	}, [objectWorldDoor])

	const setDestinationValues = async () => {
		if (gettypeContextSpace() === 'World') {
			if (objectWorldDoor.world_door_path !== null) {
				// HACIA UN MUNDO
				if (objectWorldDoor.world_door_path.world_door_destination) {
					returnDestinationWorldDoor(
						objectWorldDoor.world_door_path.world_door_destination
					)
				}
				// HACIA UN SUBMUNDO
				if (
					objectWorldDoor.world_door_path.sub_world_door_destination
				) {
					returnDestinationSubWorldDoor(
						objectWorldDoor.world_door_path
							.sub_world_door_destination
					)
				}
			}
		} else if (gettypeContextSpace() === 'Subworld') {
			if (objectWorldDoor.sub_world_door_path !== null) {
				// HACIA UN MUNDO
				if (
					objectWorldDoor.sub_world_door_path.world_door_destination
				) {
					returnDestinationWorldDoor(
						objectWorldDoor.sub_world_door_path
							.world_door_destination
					)
				}
				// HACIA UN SUBMUNDO
				if (
					objectWorldDoor.sub_world_door_path
						.sub_world_door_destination
				) {
					returnDestinationSubWorldDoor(
						objectWorldDoor.sub_world_door_path
							.sub_world_door_destination
					)
				}
			}
		}
	}

	const returnDestinationWorldDoor = async (idDoor) => {
		// SEARCH DOOR DESTINATION
		const result_door_destination = await axiosSupreme(
			'get',
			API_MANAGE_DOORS + idDoor + '/',
			undefined
		)
		// SEARCH SCENE DESTINATION
		const result_scene_destination = await axiosSupreme(
			'get',
			API_MANAGE_SCENE_WORLDS +
				result_door_destination.world_scene.id_world_scene +
				'/',
			undefined
		)
		ChangeWorldOrSubworld('Mundo', setWorldOrSubworld)
		ChangeObjectWorldOrSubworld(
			result_scene_destination.world.id_world,
			setObjectWorldOrSubworld,
			'Mundo'
		)
		ChangeScene(
			result_scene_destination.id_world_scene,
			setObjectScene,
			'Mundo'
		)
		setObjectDoor(result_door_destination.id_world_door)
	}

	const returnDestinationSubWorldDoor = async (idDoor) => {
		// SEARCH DOOR DESTINATION
		const result_door_destination = await axiosSupreme(
			'get',
			API_MANAGE_SUBWORLD_DOORS + idDoor + '/',
			undefined
		)
		// SEARCH SCENE DESTINATION
		const result_scene_destination = await axiosSupreme(
			'get',
			API_MANAGE_SCENE_SUB_WORLDS +
				result_door_destination.sub_world_scene.id_scene_sub_world +
				'/',
			undefined
		)
		ChangeWorldOrSubworld('Submundo', setWorldOrSubworld)
		ChangeObjectWorldOrSubworld(
			result_scene_destination.sub_world.id_sub_world,
			setObjectWorldOrSubworld,
			'Submundo'
		)
		ChangeScene(
			result_scene_destination.id_scene_sub_world,
			setObjectScene,
			'Submundo'
		)
		setObjectDoor(result_door_destination.id_sub_world_door)
	}

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setWorldOrSubworld(undefined)
		setObjectWorldOrSubworld(undefined)
		setObjectScene(undefined)
		setObjectDoor(undefined)
	}

	// SUBMIT FORM DOOR
	const onSubmitDestination = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()

		if (gettypeContextSpace() === 'World') {
			formData.append('world_door', objectWorldDoor.id_world_door)
		} else if (gettypeContextSpace() === 'Subworld') {
			formData.append('sub_world_door', objectWorldDoor.id_sub_world_door)
		}

		if (worldOrSubworld === 'Mundo') {
			formData.append('sub_world_door_destination', '')
			formData.append(
				'world_door_destination',
				event.target.elements.obj_door.value
			)
		} else if (worldOrSubworld === 'Submundo') {
			formData.append('world_door_destination', '')
			formData.append(
				'sub_world_door_destination',
				event.target.elements.obj_door.value
			)
		}

		let urlRequest =
			gettypeContextSpace() === 'World'
				? API_MANAGE_PATH_DOORS
				: API_MANAGE_PATH_SUBWORLD_DOORS
		if (gettypeContextSpace() === 'World') {
			if (objectWorldDoor.world_door_path === null) {
				// SAVE
				manageDoor(urlRequest, formData, 'add')
			} else {
				// UPDATE
				urlRequest +=
					objectWorldDoor.world_door_path.id_world_door_path + '/'
				manageDoor(urlRequest, formData, 'update')
			}
		} else if (gettypeContextSpace() === 'Subworld') {
			if (objectWorldDoor.sub_world_door_path === null) {
				// SAVE
				manageDoor(urlRequest, formData, 'add')
			} else {
				// UPDATE
				urlRequest +=
					objectWorldDoor.sub_world_door_path.id_sub_world_door_path +
					'/'
				manageDoor(urlRequest, formData, 'update')
			}
		}
	}

	const manageDoor = async (url, obj, action) => {
		try {
			// PROCESS DATA
			await axiosSupreme(action === 'add' ? 'post' : 'patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			getNewObject()
			setStateLoading(false)
			openModal()
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const getNewObject = async () => {
		let urlRequest =
			gettypeContextSpace() === 'World'
				? API_MANAGE_DOORS
				: API_MANAGE_SUBWORLD_DOORS
		const result_data = await axiosSupreme(
			'get',
			(urlRequest +=
				(gettypeContextSpace() === 'World'
					? objectWorldDoor.id_world_door
					: objectWorldDoor.id_sub_world_door) + '/'),
			undefined
		)
		setStateObjectDoor(result_data)
	}

	const ChangeWorldOrSubworld = async (value, setFunction) => {
		if (value === '') {
			setFunction(undefined)
		} else {
			let urlRequest = ''

			setFunction(value)
			const urlMap = {
				Mundo: GET_WORLDS_BY_GAME_LANGUAGE,
				Submundo: GET_SUB_WORLD_BY_GAME_LANGUAGE,
			}
			let { id_game_language } = JSON.parse(getGameLanguage())
			console.log('MIRAR ESTO: ', id_game_language)
			if (urlMap[value]) {
				urlRequest = urlMap[value] + id_game_language + '/'
			}
			setObjectWorldOrSubworld(undefined)
			setObjectScene(undefined)
			setObjectDoor(undefined)
			setOptionsObjectWorldOrSubworld({})
			await fetchOptionsSelectObjectWorldSubworld(
				axiosSupreme,
				urlRequest,
				setOptionsObjectWorldOrSubworld
			)
		}
	}

	const ChangeObjectWorldOrSubworld = async (
		value,
		setFunction,
		typeWorld = null
	) => {
		if (value === '') {
			setFunction(undefined)
		} else {
			let urlRequest = ''
			setFunction(value)
			if (worldOrSubworld === 'Mundo' || typeWorld === 'Mundo') {
				urlRequest = GET_SCENE_WORLD_BY_WORLD + value + '/'
			} else if (
				worldOrSubworld === 'Submundo' ||
				typeWorld === 'Submundo'
			) {
				urlRequest = GET_SCENE_SUBWORLD_BY_SUBWORLD + value + '/'
			}
			setOptionsScenes({})
			setObjectScene(undefined)
			setObjectDoor(undefined)
			await fetchOptionsSelectScenes(
				axiosSupreme,
				urlRequest,
				setOptionsScenes
			)
		}
	}

	const ChangeScene = async (value, setFunction, typeWorld = null) => {
		if (value === '') {
			setFunction(undefined)
		} else {
			let urlRequest = ''
			setFunction(value)
			if (worldOrSubworld === 'Mundo' || typeWorld === 'Mundo') {
				urlRequest = GET_DOORS_BY_SCENE + value + '/'
			} else if (
				worldOrSubworld === 'Submundo' ||
				typeWorld === 'Submundo'
			) {
				urlRequest = GET_SUBWORLD_DOORS_BY_SCENE + value + '/'
			}
			setOptionsDoors({})
			setObjectDoor(undefined)
			await fetchOptionsSelectDoors(
				axiosSupreme,
				urlRequest,
				setOptionsDoors,
				objectWorldDoor
			)
		}
	}

	return (
		<form onSubmit={onSubmitDestination}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t(
						'pages.editor.components.form_destination_door.world_or_subworld'
					)}
				</label>
				<select
					name='world_or_subworld'
					className='admin__container__inputs__in'
					id='world_or_subworld'
					placeholder={t(
						'pages.editor.components.form_destination_door.door_dependency_and_placeholder'
					)}
					autoComplete='off'
					value={worldOrSubworld}
					required
					onChange={(event) =>
						ChangeWorldOrSubworld(
							event.target.value,
							setWorldOrSubworld
						)
					}>
					<option value=''>
						{t(
							'pages.editor.components.form_destination_door.select'
						)}
					</option>
					{optionsWorldOrSubworld.map((data_item, index) => (
						<option key={data_item + '.' + index} value={data_item}>
							{data_item}
						</option>
					))}
				</select>
			</div>

			{worldOrSubworld !== undefined ? (
				<>
					<div
						className='admin__container__inputs'
						id='admin__container__inputs'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{worldOrSubworld}
						</label>
						<select
							name='obj_world_or_subworld'
							className='admin__container__inputs__in'
							id='obj_world_or_subworld'
							placeholder={t(
								'pages.editor.components.form_destination_door.door_dependency_and_placeholder'
							)}
							autoComplete='off'
							value={objectWorldOrSubworld}
							required
							onChange={(event) =>
								ChangeObjectWorldOrSubworld(
									event.target.value,
									setObjectWorldOrSubworld
								)
							}>
							<option value=''>
								{t(
									'pages.editor.components.form_destination_door.select'
								)}
							</option>
							{Object.keys(optionsObjectWorldOrSubworld).map(
								(key) => (
									<option key={key} value={key}>
										{optionsObjectWorldOrSubworld[key].name}
									</option>
								)
							)}
						</select>
					</div>

					{objectWorldOrSubworld !== undefined ? (
						<>
							<div
								className='admin__container__inputs'
								id='admin__container__inputs'>
								<label
									className='admin__container__inputs__title'
									forhtml='admin__container__inputs__title'>
									{t(
										'pages.editor.components.form_destination_door.escene'
									)}
								</label>
								<select
									name='obj_scene'
									className='admin__container__inputs__in'
									id='obj_scene'
									placeholder={t(
										'pages.editor.components.form_destination_door.door_dependency_and_placeholder'
									)}
									autoComplete='off'
									value={objectScene}
									required
									onChange={(event) =>
										ChangeScene(
											event.target.value,
											setObjectScene
										)
									}>
									<option value=''>
										{t(
											'pages.editor.components.form_destination_door.select'
										)}
									</option>
									{Object.keys(optionsScenes).map((key) => (
										<option key={key} value={key}>
											{optionsScenes[key].name}
										</option>
									))}
								</select>
							</div>

							{objectScene !== undefined ? (
								<>
									<div
										className='admin__container__inputs'
										id='admin__container__inputs'>
										<label
											className='admin__container__inputs__title'
											forhtml='admin__container__inputs__title'>
											{t(
												'pages.editor.components.form_destination_door.door'
											)}
										</label>
										<select
											name='obj_door'
											className='admin__container__inputs__in'
											id='obj_door'
											placeholder={t(
												'pages.editor.components.form_destination_door.door_dependency_and_placeholder'
											)}
											autoComplete='off'
											value={objectDoor}
											required
											onChange={(event) =>
												onInputChangeDefault(
													event,
													setObjectDoor
												)
											}>
											<option value=''>
												{t(
													'pages.editor.components.form_destination_door.select'
												)}
											</option>
											{Object.keys(optionsDoors).map(
												(key) => (
													<option
														key={key}
														value={key}>
														{optionsDoors[key].name}
													</option>
												)
											)}
										</select>
									</div>

									{stateLoading === false ? (
										<div className='admin__container__boxBtn'>
											<SaveButton></SaveButton>
										</div>
									) : (
										<LoadingIcon />
									)}
								</>
							) : null}
						</>
					) : null}
				</>
			) : null}
		</form>
	)
}

const fetchOptionsSelectObjectWorldSubworld = async (
	axiosSupreme,
	url,
	setList
) => {
	const fetchData = async (url) => {
		const processedUrl = returnProcessUrl(url)
		const resultData = await axiosSupreme('get', processedUrl, undefined)

		setList((prevData) => ({
			...prevData,
			...resultData.results.reduce((acc, dataItem) => {
				const key = dataItem.hasOwnProperty('id_world')
					? dataItem.id_world
					: dataItem.id_sub_world
				acc[key] = { name: dataItem.name }
				return acc
			}, {}),
		}))

		if (resultData.next) {
			await fetchData(resultData.next)
		}
	}

	await fetchData(url)
}

const fetchOptionsSelectScenes = async (axiosSupreme, url, setList) => {
	const fetchData = async (url) => {
		const processedUrl = returnProcessUrl(url)
		const resultData = await axiosSupreme('get', processedUrl, undefined)

		setList((prevData) => ({
			...prevData,
			...resultData.results.reduce((acc, dataItem) => {
				const key = dataItem.hasOwnProperty('id_world_scene')
					? dataItem.id_world_scene
					: dataItem.id_scene_sub_world
				acc[key] = { name: dataItem.name }
				return acc
			}, {}),
		}))

		if (resultData.next) {
			await fetchData(resultData.next)
		}
	}

	await fetchData(url)
}

const fetchOptionsSelectDoors = async (
	axiosSupreme,
	url,
	setList,
	objectWorldDoor
) => {
	const fetchData = async (url) => {
		const processedUrl = returnProcessUrl(url)
		const resultData = await axiosSupreme('get', processedUrl, undefined)

		setList((prevData) => ({
			...prevData,
			...resultData.results.reduce((acc, dataItem) => {
				const idDoor = objectWorldDoor.hasOwnProperty('id_world_door')
					? objectWorldDoor.id_world_door
					: objectWorldDoor.id_sub_world_door
				const idDoorItem = dataItem.hasOwnProperty('id_world_door')
					? dataItem.id_world_door
					: dataItem.id_sub_world_door

				if (idDoor !== idDoorItem) {
					const key = dataItem.hasOwnProperty('id_world_door')
						? dataItem.id_world_door
						: dataItem.id_sub_world_door
					acc[key] = { name: dataItem.name }
				}

				return acc
			}, {}),
		}))

		if (resultData.next) {
			await fetchData(resultData.next)
		}
	}

	await fetchData(url)
}
