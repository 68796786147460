import { useContext, useEffect, useState } from 'react'
import { FormGame } from './FormGame'
import ModalContext from '../../../../../context/editor/ModalContext'
import { API_MANAGE_GAMES } from '../../../../../utils/constantsAdmin'
import { SearchCamp } from '../../../searchAndPagination/SearchCamp'
import { GameCollapse } from './GameCollapse'
import { Pagination } from '../../../searchAndPagination/Pagination'
import { LoadingIcon } from '../../../LoadingIcon'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListGames = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// REFRESH GAMES
	const [refreshGames, setRefreshGames] = useState(false)

	// DATA
	const [dataGames, setDataGames] = useState(undefined)

	// GET ALL GAMES
	useEffect(() => {
		const getData = async () => {
			try {
				const result_data = await axiosSupreme('get', API_MANAGE_GAMES)
				setDataGames(result_data)
			} catch (e) {
				console.log('Error get Data: ', e)
			}
		}
		getData()
	}, [refreshGames])

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddGames = () => {
		setTitleModal(t('pages.editor.components.list_games.title_modal'))
		setContentModal(<FormGame setRefreshGames={setRefreshGames} />)
		openModal()
	}

	// ACTIVECOLLAPSE
	const [activeCollapse, setActiveCollapse] = useState(undefined)

	return (
		<>
			{dataGames !== undefined ? (
				<>
					<div className='challengeAndGameDataContent__btnAdd'>
						<h1>{t('pages.editor.components.list_games.title')}</h1>
						<div className='boxBtnAdd'>
							<button
								className='editorBtnActionAdd'
								onClick={OpenAddGames}>
								{t('common.add')}
							</button>
						</div>
					</div>
					{dataGames === 'search' || dataGames.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setDataGames}
								setRefreshDataList={setRefreshGames}
								url={API_MANAGE_GAMES}
								search={search}
								setSearch={setSearch}
							/>
							{dataGames === 'search' ? (
								<p className='noSelect'>
									{t(
										'pages.editor.components.list_games.none_game'
									)}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t('pages.editor.components.list_games.none_game')}
						</p>
					)}
					{dataGames !== 'search' ? (
						<>
							{dataGames.results.map((data_item, index) => (
								<GameCollapse
									key={data_item.id_game}
									objectGame={data_item}
									setRefreshGames={setRefreshGames}
									activeCollapse={activeCollapse}
									setActiveCollapse={setActiveCollapse}
								/>
							))}
							<Pagination
								data={dataGames}
								setData={setDataGames}></Pagination>
						</>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</>
	)
}
