import React, { useState } from 'react'
import useAuthNavigator from '../context/AuthProvider/useAuthNavigator'
import { useNavigate } from 'react-router-dom'

import myCookies from '../utils/myCookies'
import { useAuthProvider } from '../context/AuthProvider/AuthProvider'
import '../styles/Start.scss'

import startImageAvif from '../assets/images/start.avif'
import startImagePng from '../assets/images/start.png'
import teodoroImageAvif from '../assets/images/teodoro1.avif'
import teodoroImagePng from '../assets/images/teodoro1.png'
import teoloroLettersImageAvif from '../assets/images/teoloroLetters.avif'
import teoloroLettersImagePng from '../assets/images/teoloroLetters.png'
import trailer from '../assets/videos/1080introjuego.mp4'
import trailerWebm from '../assets/videos/1080introjuego.webm'
import CompanyBar from '../components/shared/CompanyBar/CompanyBar'

//Flags
import suomiFlag from '../assets/Flags/bandera-finlandia.png'
import spainFlag from '../assets/Flags/bandera-espana.png'
import deutschFlag from '../assets/Flags/bandera-alemania.png'
import francaisFlag from '../assets/Flags/bandera-francia.png'
import svenskaFlag from '../assets/Flags/bandera-suecia.png'
import englishFlag from '../assets/Flags/bandera-inglaterra.webp'
import firstGrade from '../assets/Flags/number_one.png'
import fifthGrade from '../assets/Flags/number_five.webp'

const Start = () => {
	return useAuthNavigator(<PrivatePage />)
}

function PrivatePage() {
	const navigate = useNavigate()
	const { gameUser, gameLanguageOptions, updateGameUser } = useAuthProvider()

	const [selectedGameLanguage, setSelectedGameLanguage] = useState()
	// myCookies.get.gameLanguageID()
	const [showTrailer, setShowTrailer] = useState(false)

	const onStart = () => {
		if (gameUser.saw_trailer) {
			navigate('/game')
		} else {
			setShowTrailer(true)
		}
	}

	const onSelectChange = (idGameLanguage) => {
		setSelectedGameLanguage(idGameLanguage)
		myCookies.set.gameLanguageID(idGameLanguage)
		updateGameUser()
	}

	const endTutorial = () => {
		navigate('/game')
	}

	return (
		<div className='start'>
			{showTrailer && (
				<div className='containerTutorial'>
					<video
						autoPlay
						className='containerTutorialVideo'
						onEnded={endTutorial}>
						<source src={trailerWebm} type='video/webm' />
						<source src={trailer} type='video/mp4' />
					</video>

					<button
						className='containerTutorialSkipTutorial'
						onClick={endTutorial}>
						SKIP
					</button>
				</div>
			)}
			<div className='start__company__bar'>
				<CompanyBar />
			</div>
			<div className='start__page'>
				<picture className='start__teodoro'>
					<source srcSet={teodoroImageAvif} type='image/avif' />
					<img
						src={teodoroImagePng}
						alt='pájaro teoloro'
						loading='lazy'
						decoding='async'
					/>
				</picture>
				<div className='start__subscriptions'>
					{gameLanguageOptions?.map((sub, index) => (
						<button
							onClick={() =>
								onSelectChange(
									sub.game_language.id_game_language
								)
							}
							className={`start__subscriptions__button ${
								selectedGameLanguage ===
								sub.game_language.id_game_language
									? 'start__subscriptions__selected'
									: ''
							}`}
							key={index}>
							<picture className='start__subscriptions__container'>
								<img
									className='start__subscriptions__container__flag1'
									src={
										sub.game_language.language_to_learn ===
										'español'
											? spainFlag
											: sub.game_language
													.language_to_learn ===
											  'soumi'
											? suomiFlag
											: sub.game_language
													.language_to_learn ===
											  'deutsch'
											? deutschFlag
											: sub.game_language
													.language_to_learn ===
											  'francais'
											? francaisFlag
											: sub.game_language
													.language_to_learn ===
											  'svenska'
											? svenskaFlag
											: sub.game_language
													.language_to_learn ===
											  'english'
											? englishFlag
											: sub.game_language
													.language_to_learn ===
											  'first_grade'
											? firstGrade
											: sub.game_language
													.language_to_learn ===
											  'fifth_grade'
											? fifthGrade
											: ''
									}
									alt={`${sub.game_language.language_to_learn}flag`}
									loading='lazy'
									decoding='async'
								/>
								<img
									className='start__subscriptions__container__flag2'
									src={
										sub.game_language.base_language ===
										'español'
											? spainFlag
											: sub.game_language
													.base_language === 'soumi'
											? suomiFlag
											: sub.game_language
													.base_language === 'deutsch'
											? deutschFlag
											: sub.game_language
													.base_language ===
											  'francais'
											? francaisFlag
											: sub.game_language
													.base_language === 'svenska'
											? svenskaFlag
											: sub.game_language
													.base_language === 'english'
											? englishFlag
											: sub.game_language
													.base_language ===
											  'first_grade'
											? firstGrade
											: sub.game_language
													.base_language ===
											  'fifth_grade'
											? fifthGrade
											: ''
									}
									alt={`${sub.game_language.language_to_learn}flag`}
									loading='lazy'
									decoding='async'
								/>
							</picture>
						</button>
					))}
				</div>
				<div className='start__right'>
					<picture>
						<source
							srcSet={teoloroLettersImageAvif}
							type='image/avif'
						/>
						<img
							src={teoloroLettersImagePng}
							alt='Teoloro'
							loading='lazy'
							decoding='async'
						/>
					</picture>
					<button
						className={`start__right__start__button ${
							selectedGameLanguage == null
								? 'start__right__start__button__hidden'
								: ''
						}`}
						onClick={onStart}>
						<picture>
							<source srcSet={startImageAvif} type='image/avif' />
							<img
								src={startImagePng}
								alt='Botón de Inicio'
								loading='lazy'
								decoding='async'
							/>
						</picture>
					</button>
				</div>
			</div>
		</div>
	)
}

export default Start
