import { useContext, useEffect, useRef, useState } from 'react'
import SaveButton from '../../SaveButton'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import {
	onInputChangeDefault,
	onInputChangeImage,
	onInputJson,
	processErrors,
	returnProcessUrl,
} from '../../../../utils/general_functions_forms'
import {
	API_MANAGE_SCENE_SUB_WORLDS,
	API_MANAGE_SOUNDS,
} from '../../../../utils/constantsAdmin'
import { LoadingIcon } from '../../LoadingIcon'
import { ManageFile } from '../ManageFile'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export function FormSceneSubWorld({
	idSubWorld,
	stateObjectSceneSubWorld,
	setStateObjectSceneSubWorld,
	setRefreshSceneSubWorlds,
}) {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [name, setName] = useState('')
	const [background, setBackground] = useState('#ffffff')
	const imageFile = useRef(null)
	const collisionFile = useRef(null)
	const [sound, setSound] = useState('')
	const [ambientSound, setAmbientSound] = useState('')

	// LIST VALUES
	const [listSounds, setListSounds] = useState({})
	useEffect(() => {
		fetchOptionsSounds(axiosSupreme, API_MANAGE_SOUNDS, setListSounds)
	}, [])

	// ERROR FOR FORM
	const [errorImage, setErrorImage] = useState('')
	const [errorJson, setErrorJson] = useState('')

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		setBackground('#ffffff')
		imageFile.current.value = null
		collisionFile.current.value = null
	}

	// SET SCENEWORLD WHEN UPDATE
	useEffect(() => {
		if (stateObjectSceneSubWorld !== undefined) {
			setName(stateObjectSceneSubWorld.name)
			if (stateObjectSceneSubWorld.background_color) {
				setBackground(stateObjectSceneSubWorld.background_color)
			} else {
				setBackground('#ffffff')
			}
			if (stateObjectSceneSubWorld.sound) {
				setSound(stateObjectSceneSubWorld.sound.id_sound)
			}
			if (stateObjectSceneSubWorld.ambient_sound) {
				setAmbientSound(stateObjectSceneSubWorld.ambient_sound.id_sound)
			}
		} else {
			clearFields()
		}
	}, [stateObjectSceneSubWorld])

	// SUBMIT FORM SCENEWORLD
	const onSubmitSceneSubWorld = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('sub_world', idSubWorld)
		formData.append('name', name)
		formData.append('background_color', background)
		formData.append('sound', sound ? sound : '')
		formData.append('ambient_sound', ambientSound ? ambientSound : '')
		if (event.target.elements.image_file.value) {
			formData.append(
				'image_file',
				event.target.elements.image_file.files[0]
			)
		}
		if (event.target.elements.collision_file.value) {
			formData.append(
				'collision_file',
				event.target.elements.collision_file.files[0]
			)
		}
		if (stateObjectSceneSubWorld === undefined) {
			// SAVE
			manageSceneSubWorld(
				event,
				API_MANAGE_SCENE_SUB_WORLDS,
				formData,
				'add'
			)
		} else {
			// UPDATE
			manageSceneSubWorld(
				event,
				API_MANAGE_SCENE_SUB_WORLDS +
					stateObjectSceneSubWorld.id_scene_sub_world +
					'/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE SCENEWORLD
	const manageSceneSubWorld = async (event, url, obj, action) => {
		try {
			// PROCESS DATA
			await axiosSupreme(action === 'add' ? 'post' : 'patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshSceneSubWorlds((prev) => !prev)
			if (action === 'update') getNewObject()
			setStateLoading(false)
			openModal()
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
	}

	const getNewObject = async () => {
		const result_data = await axiosSupreme(
			'get',
			API_MANAGE_SCENE_SUB_WORLDS +
				stateObjectSceneSubWorld.id_scene_sub_world +
				'/',
			undefined
		)
		setStateObjectSceneSubWorld(result_data)
	}

	return (
		<>
			<form onSubmit={onSubmitSceneSubWorld}>
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_scene_sub_world.name')}
					</label>
					<input
						maxLength='45'
						name='name'
						className='admin__container__inputs__in'
						id='name'
						type='text'
						placeholder={t(
							'pages.editor.components.form_scene_sub_world.name_placeholder'
						)}
						autoComplete='off'
						value={name}
						onChange={(event) =>
							onInputChangeDefault(event, setName)
						}
						required></input>
				</div>

				<div
					className='admin__container__inputsColor'
					id='admin__container__inputsColor'>
					<label
						className='admin__container__inputsColor__title'
						forhtml='admin__container__inputsColor__title'>
						{t(
							'pages.editor.components.form_scene_sub_world.background_color_and_placeholder'
						)}
					</label>
					<div className='colorbox'>
						<input
							name='background'
							className='backgroundColorBox'
							id='background'
							type='color'
							value={background}
							onChange={(event) =>
								onInputChangeDefault(event, setBackground)
							}></input>
						<input
							maxLength='45'
							name='background'
							className='admin__container__inputsColor__in'
							id='background'
							type='text'
							placeholder={t(
								'pages.editor.components.form_scene_sub_world.background_color_and_placeholder'
							)}
							autoComplete='off'
							value={background}
							onChange={(event) =>
								onInputChangeDefault(event, setBackground)
							}></input>
					</div>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_scene_sub_world.image'
						)}
					</label>
					{stateObjectSceneSubWorld !== undefined ? (
						<>
							{stateObjectSceneSubWorld.image_file !== null ? (
								<label htmlFor=''>
									{t('common.current_file')}
									<a
										href={
											stateObjectSceneSubWorld.image_file
										}
										target='_blank'
										rel='noopener noreferrer'>
										{t('common.open')}
									</a>
								</label>
							) : null}
						</>
					) : null}
					<input
						maxLength='300'
						name='image_file'
						className='admin__container__inputs__in'
						id='admin__container__inputs__in'
						type='file'
						required={stateObjectSceneSubWorld === undefined}
						ref={imageFile}
						accept='image/*'
						onChange={(event) =>
							onInputChangeImage(event, setErrorImage, imageFile)
						}></input>
					{errorImage && <p>{errorImage}</p>}
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_scene_sub_world.collition_file'
						)}
					</label>
					{stateObjectSceneSubWorld !== undefined ? (
						<>
							{stateObjectSceneSubWorld.collision_file !==
							null ? (
								<ManageFile
									pathFile={
										stateObjectSceneSubWorld.collision_file
									}
									attribute='collision_file'
									url={
										API_MANAGE_SCENE_SUB_WORLDS +
										stateObjectSceneSubWorld.id_scene_sub_world +
										'/'
									}
									getNewObject={getNewObject}
									setStateObject={setStateObjectSceneSubWorld}
								/>
							) : null}
						</>
					) : null}
					<input
						maxLength='300'
						name='collision_file'
						className='admin__container__inputs__in'
						id='collision_file'
						type='file'
						ref={collisionFile}
						onChange={(event) =>
							onInputJson(event, setErrorJson, collisionFile)
						}
						accept='application/JSON'></input>
					{errorJson && <p>{errorJson}</p>}
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_scene_sub_world.instrumental_sound'
						)}
					</label>
					<select
						name='sound'
						className='admin__container__inputs__in'
						id='sound'
						placeholder={t(
							'pages.editor.components.form_scene_sub_world.sound_placeholder'
						)}
						autoComplete='off'
						value={sound}
						onChange={(event) =>
							onInputChangeDefault(event, setSound)
						}>
						<option key='' value=''>
							{t(
								'pages.editor.components.form_scene_sub_world.select'
							)}
						</option>
						{Object.keys(listSounds).map((key) => (
							<option key={key} value={key}>
								{listSounds[key].name}
							</option>
						))}
					</select>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_scene_sub_world.ambient_sound'
						)}
					</label>
					<select
						name='ambient_sound'
						className='admin__container__inputs__in'
						id='ambient_sound'
						placeholder={t(
							'pages.editor.components.form_scene_sub_world.sound_placeholder'
						)}
						autoComplete='off'
						value={ambientSound}
						onChange={(event) =>
							onInputChangeDefault(event, setAmbientSound)
						}>
						<option key='' value=''>
							{t(
								'pages.editor.components.form_scene_sub_world.select'
							)}
						</option>
						{Object.keys(listSounds).map((key) => (
							<option key={key} value={key}>
								{listSounds[key].name}
							</option>
						))}
					</select>
				</div>

				{stateLoading === false ? (
					<div className='admin__container__boxBtn'>
						<SaveButton></SaveButton>
					</div>
				) : (
					<LoadingIcon />
				)}
			</form>
		</>
	)
}

const fetchOptionsSounds = async (axiosSupreme, url, setList) => {
	const fetchData = async (url) => {
		const processedUrl = returnProcessUrl(url)
		const resultData = await axiosSupreme('get', processedUrl, undefined)

		setList((prevData) => ({
			...prevData,
			...resultData.results.reduce((acc, dataItem) => {
				acc[dataItem.id_sound] = {
					name: dataItem.name,
				}
				return acc
			}, {}),
		}))

		if (resultData.next) {
			await fetchData(resultData.next)
		}
	}

	await fetchData(url)
}
