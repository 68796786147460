// GENERAL
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import '../../../styles/AdminDesign.scss'
import { Toaster } from 'react-hot-toast'
// COMPONENTS
import { Alert } from '../../../components/editor/Alert'
import Modal from '../../../components/editor/Modal'
import ModalDelete from '../../../components/editor/ModalDelete'
import { ErrorPerms } from '../../../components/editor/ErrorPerms'
import TitleAdministratorAndMenu from '../../../components/editor/TitleAdministratorAndMenu'
import { BackButton } from '../../../components/editor/BackButton'
import { ListLevelsLanguage } from '../../../components/editor/design/LevelsLanguage/ListLevelsLanguage'
import { ObjectLevelLanguageProvider } from '../../../components/editor/design/LevelsLanguage/ObjectLevelLanguage'
import { ButtonAdminLevelLanguage } from '../../../components/editor/design/ButtonAdminLevelLanguage'
import { ListGameLanguage } from '../../../components/editor/design/GameLanguages/ListGameLanguage'
// CONTEXT
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'
import useAuthNavigator from '../../../context/AuthProvider/useAuthNavigator'
import ModalContext from '../../../context/editor/ModalContext'
// UTILS
import { ManageStorageGameLanguage } from '../../../utils/manage_local_storage'
import { USER_API } from '../../../utils/constants'
import myCookies from '../../../utils/myCookies'

const Editor = () => {
	return useAuthNavigator(<PrivatePage />)
}

const PrivatePage = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// LOCAL STORAGE
	const { getGameLanguage } = ManageStorageGameLanguage()
	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	const [renderAdmin, setRenderAdmin] = useState(false)

	// RESET SCROLL
	useEffect(() => {
		const fetchDataAndSetup = async () => {
			await getDataUser()
			if (
				'view_administrator_game' in
				myCookies.get.gameUser()?.permissions
			) {
				if (getGameLanguage()) {
					setRenderAdmin(true)
				} else {
					selectGameLanguage()
				}
			} else {
				navigate('/')
			}
		}

		fetchDataAndSetup()
	}, [gameUser, navigate])

	const getDataUser = async () => {
		try {
			const response = await axiosSupreme(
				'get',
				USER_API,
				myCookies.get.gameLanguageID()
			)
			myCookies.set.gameUser(response.data)
		} catch (e) {
			if (!window.location.pathname.includes('/editor'))
				window.location.replace('/')
		}
	}

	const isEmpty = (obj) => {
		return JSON.stringify(obj) === '{}'
	}

	const selectGameLanguage = () => {
		setTitleModal('Lenguajes de Juego')
		setContentModal(<ListGameLanguage />)
		openModal()
	}

	return (
		<div className='adminD'>
			{!isEmpty(gameUser) &&
			'view_administrator_game' in gameUser.permissions ? (
				<ObjectLevelLanguageProvider>
					<Alert />
					<Modal />
					<ModalDelete />
					<Toaster />
					<TitleAdministratorAndMenu
						name={t(
							'pages.editor.components.title_admin_menu.content'
						)}
					/>
					<BackButton url={'/'} />
					<ButtonAdminLevelLanguage />
					{renderAdmin && <ListLevelsLanguage />}
				</ObjectLevelLanguageProvider>
			) : (
				<ErrorPerms />
			)}
		</div>
	)
}

export default Editor
