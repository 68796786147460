import { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../context/editor/ModalContext'
import { FormStars } from './FormStars'
import { API_MANAGE_STARS } from '../../../../utils/constantsAdmin'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import { Pagination } from '../../searchAndPagination/Pagination'
import { LoadingIcon } from '../../LoadingIcon'
import { ItemStar } from './ItemStar'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListStars = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// REFRESH ANIMATIONS
	const [refreshStars, setRefreshStars] = useState(false)

	// DATA
	const [data, setData] = useState(undefined)

	// GET ALL ANIMATIONS
	useEffect(() => {
		const getData = async () => {
			try {
				const result_data = await axiosSupreme('get', API_MANAGE_STARS)
				setData(result_data)
			} catch (e) {
				console.error('Error get Data: ', e)
			}
		}
		getData()
	}, [refreshStars])

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddStarsModal = () => {
		setTitleModal(t('pages.editor.components.list_stars.add_stars'))
		setContentModal(<FormStars setRefreshStars={setRefreshStars} />)
		openModal()
	}

	return (
		<div className='adminDesign__container__mainContainerBank'>
			<div className='adminDesign__container__mainContainerBank__Item'>
				<div className='adminDesign__container__mainContainerBank__Item__title'>
					<h1>
						{t('pages.editor.components.list_stars.bank_stars')}
					</h1>
				</div>
				<div className='adminDesign__container__mainContainerBank__Item__content'>
					<hr />
					<div className='animations__container__btnAdd'>
						<div className='boxBtnAdd'>
							<button
								className='editorBtnActionAdd'
								onClick={OpenAddStarsModal}>
								{t('common.add')}
							</button>
						</div>
					</div>
					{data !== undefined ? (
						<>
							{data === 'search' || data.results.length > 0 ? (
								<>
									<SearchCamp
										setData={setData}
										setRefreshDataList={setRefreshStars}
										url={API_MANAGE_STARS}
										search={search}
										setSearch={setSearch}
									/>
									{data === 'search' ? (
										<p className='noSelect'>
											{t(
												'pages.editor.components.list_stars.none_stars_found'
											)}
										</p>
									) : null}
								</>
							) : (
								<p className='noSelect'>
									{t(
										'pages.editor.components.list_stars.none_stars_loaded'
									)}
								</p>
							)}
							{data !== 'search' ? (
								<div className='starsContent'>
									{data.results.map((data_item, index) => (
										<ItemStar
											key={data_item.id_star}
											objectStar={data_item}
											setRefreshStars={setRefreshStars}
										/>
									))}
									<Pagination data={data} setData={setData} />
								</div>
							) : null}
						</>
					) : (
						<LoadingIcon />
					)}
				</div>
			</div>
		</div>
	)
}
