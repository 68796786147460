import { useContext, useEffect, useState } from 'react'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import {
	API_MANAGE_CHALLENGES,
	API_MANAGE_GAMES,
	API_MANAGE_MISSIONS,
	API_MANAGE_STORE_ITEMS,
} from '../../../../utils/constantsAdmin'
import SaveButton from '../../SaveButton'
import {
	onInputChangeDefault,
	processErrors,
	returnProcessUrl,
} from '../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

const valuesTypeMission = {
	l: 'Clase',
	g: 'Juego',
	o: 'Objeto Perdido',
	ee: 'Huevo de Pascua',
}

export const FormMissions = ({
	idSubWorldSceneNpcAppearance,
	objectMission,
	setStateObjectMission,
	setRefreshMissions,
	typeMission,
	setRefreshObjectNpc,
}) => {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// VALUES FORM
	const [order, setOrder] = useState('')
	const [description, setDescription] = useState('')
	const [challenge, setChallenge] = useState('')
	const [valueTypeMission, setValueTypeMission] = useState(typeMission)
	const [item, setItem] = useState('')
	const [game, setGame] = useState('')

	// RENDER
	const [render, setRender] = useState(false)

	// LIST VALUES
	const [listItems, setListItems] = useState({})
	const [listChallenges, setListChallenges] = useState({})
	const [listGames, setListGames] = useState({})

	const getListItems = () => {
		fetchOptionsItems(axiosSupreme, API_MANAGE_STORE_ITEMS, setListItems)
		fetchOptionsGame(axiosSupreme, API_MANAGE_GAMES, setListGames)
		fetchOptionsChallenge(
			axiosSupreme,
			API_MANAGE_CHALLENGES,
			setListChallenges
		)
	}

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setOrder('')
		setDescription('')
		setItem('')
		setChallenge('')
		setGame('')
	}

	// SET MISSION WHEN UPDATE
	useEffect(() => {
		getListItems()
		if (objectMission !== undefined) {
			setOrder(objectMission.order)
			setDescription(objectMission.description)
			if (objectMission.item) {
				setItem(objectMission.item.id_item)
			}
			setChallenge(objectMission.challenge.id_challenge)
			if (objectMission.challenge.category === 'g') {
				setGame(objectMission.game.id_game)
			}
		} else {
			clearFields()
		}
		setValueTypeMission(typeMission)
	}, [objectMission])

	// SUBMIT FORM MISSION
	const onSubmitSubWorld = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('order', order)
		formData.append('description', description)
		formData.append('challenge', challenge)
		formData.append(
			'sub_world_scene_npc_appearance',
			idSubWorldSceneNpcAppearance
		)

		if (item) {
			formData.append('item', item)
		}
		if (listChallenges[event.target.challenge.value].category === 'g') {
			formData.append('game', event.target.elements.game.value)
		}
		if (objectMission === undefined) {
			// SAVE
			manageMission(API_MANAGE_MISSIONS, formData, 'add')
		} else {
			// UPDATE
			manageMission(
				API_MANAGE_MISSIONS + objectMission.id_mission + '/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE ANIMATION
	const manageMission = async (url, obj, action) => {
		try {
			if (action === 'add') {
				await axiosSupreme('post', url, obj)
			} else {
				await axiosSupreme('patch', url, obj)
			}
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshMissions((prev) => !prev)
			if (action === 'update') getNewObject()
			setRefreshObjectNpc((prev) => !prev)
			openModal()
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const getNewObject = async () => {
		const result_data = await axiosSupreme(
			'get',
			API_MANAGE_MISSIONS + objectMission.id_mission + '/',
			undefined
		)
		setStateObjectMission(result_data)
	}

	const handleChangeTypeMission = (value) => {
		setChallenge('')
		setGame('')
		setItem('')
		setValueTypeMission(value)
		getListItems()
		setRender((prev) => !prev)
	}

	return (
		<form key={render} onSubmit={onSubmitSubWorld}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_missions.order')}
				</label>
				<input
					maxLength='300'
					name='order'
					className='admin__container__inputs__in'
					id='order'
					type='number'
					placeholder={t(
						'pages.editor.components.form_missions.order_placeholder'
					)}
					autoComplete='off'
					value={order}
					onChange={(event) => onInputChangeDefault(event, setOrder)}
					required></input>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_missions.description')}
				</label>
				<textarea
					maxLength='500'
					name='description'
					className='admin__container__inputs__in'
					id='description'
					type='text-area'
					placeholder={t(
						'pages.editor.components.form_missions.description_placeholder'
					)}
					autoComplete='off'
					value={description}
					onChange={(event) =>
						onInputChangeDefault(event, setDescription)
					}
					required></textarea>
			</div>

			{objectMission !== undefined ? (
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_missions.type_mission'
						)}
					</label>
					<select
						name='typeMission'
						className='admin__container__inputs__in'
						id='typeMission'
						placeholder={t(
							'pages.editor.components.form_missions.sound_placeholder'
						)}
						autoComplete='off'
						value={valueTypeMission}
						required
						onChange={(event) =>
							handleChangeTypeMission(event.target.value)
						}>
						{Object.keys(valuesTypeMission).map((key) => (
							<option key={key} value={key}>
								{valuesTypeMission[key]}
							</option>
						))}
					</select>
				</div>
			) : null}

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t('pages.editor.components.form_missions.challenge_for')}
					{valueTypeMission === 'l'
						? t(
								'pages.editor.components.form_missions.types.classes'
						  )
						: null}
					{valueTypeMission === 'o'
						? t(
								'pages.editor.components.form_missions.types.lost_obj'
						  )
						: null}
					{valueTypeMission === 'g'
						? t('pages.editor.components.form_missions.types.games')
						: null}
					{valueTypeMission === 'ee'
						? t(
								'pages.editor.components.form_missions.types.easter_egg'
						  )
						: null}
					*
				</label>
				<select
					name='challenge'
					className='admin__container__inputs__in'
					id='challenge'
					placeholder={t(
						'pages.editor.components.form_missions.sound_placeholder'
					)}
					autoComplete='off'
					value={challenge}
					onChange={(event) =>
						onInputChangeDefault(event, setChallenge)
					}
					required>
					<option key='' value=''>
						{t(
							'pages.editor.components.form_missions.select_challenge'
						)}
					</option>
					{Object.keys(listChallenges).map(
						(key) =>
							listChallenges[key].category ===
								valueTypeMission && (
								<option key={key} value={key}>
									{listChallenges[key].name}
								</option>
							)
					)}
				</select>
			</div>

			{valueTypeMission === 'o' ? (
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_missions.object')}
					</label>
					<select
						name='item'
						className='admin__container__inputs__in'
						id='item'
						placeholder={t(
							'pages.editor.components.form_missions.sound_placeholder'
						)}
						autoComplete='off'
						value={item}
						onChange={(event) =>
							onInputChangeDefault(event, setItem)
						}
						required={valueTypeMission === 'o'}>
						<option key='' value=''>
							{t(
								'pages.editor.components.form_missions.select_item'
							)}
						</option>
						{Object.keys(listItems).map((key) => (
							<option key={key} value={key}>
								{listItems[key].name}
							</option>
						))}
					</select>
				</div>
			) : null}

			{valueTypeMission === 'g' ? (
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_missions.game_type')}
					</label>
					<select
						name='game'
						className='admin__container__inputs__in'
						id='game'
						placeholder={t(
							'pages.editor.components.form_missions.sound_placeholder'
						)}
						autoComplete='off'
						value={game}
						required={valueTypeMission === 'g'}
						onChange={(event) =>
							onInputChangeDefault(event, setGame)
						}>
						<option key='' value=''>
							{t(
								'pages.editor.components.form_missions.select_item'
							)}
						</option>
						{Object.keys(listGames).map((key) => (
							<option key={key} value={key}>
								{listGames[key].name}
							</option>
						))}
					</select>
				</div>
			) : null}

			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton></SaveButton>
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}

const fetchOptionsItems = async (axiosSupreme, url, setList) => {
	const fetchData = async (url) => {
		const processedUrl = returnProcessUrl(url)
		const resultData = await axiosSupreme('get', processedUrl, undefined)

		setList((prevData) => ({
			...prevData,
			...resultData.results.reduce((acc, dataItem) => {
				acc[dataItem.id_item] = {
					name: dataItem.name,
					image_file: dataItem.image_file,
				}
				return acc
			}, {}),
		}))

		if (resultData.next) {
			await fetchData(resultData.next)
		}
	}

	await fetchData(url)
}

const fetchOptionsChallenge = async (axiosSupreme, url, setList) => {
	const fetchData = async (url) => {
		const processedUrl = returnProcessUrl(url)
		const resultData = await axiosSupreme('get', processedUrl, undefined)

		setList((prevData) => ({
			...prevData,
			...resultData.results.reduce((acc, dataItem) => {
				acc[dataItem.id_challenge] = {
					name: dataItem.name,
					category: dataItem.category,
				}
				return acc
			}, {}),
		}))

		if (resultData.next) {
			await fetchData(resultData.next)
		}
	}

	await fetchData(url)
}

const fetchOptionsGame = async (axiosSupreme, url, setList) => {
	const fetchData = async (url) => {
		const processedUrl = returnProcessUrl(url)
		const resultData = await axiosSupreme('get', processedUrl, undefined)
		setList((prevData) => ({
			...prevData,
			...resultData.results.reduce((acc, dataItem) => {
				acc[dataItem.id_game] = {
					name: dataItem.name,
				}
				return acc
			}, {}),
		}))
		if (resultData.next) {
			await fetchData(resultData.next)
		}
	}
	await fetchData(url)
}
