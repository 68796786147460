import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useState } from 'react'
import { BsFillTrashFill, BsFillPencilFill } from 'react-icons/bs'
import { FaArrowsTurnToDots } from 'react-icons/fa6'
import ModalContext from '../../../../context/editor/ModalContext'
import { FormMissions } from './FormMissions'
import {
	API_CONTENT_MISSION,
	API_CONTENT_MISSION_ADD_ONS,
	API_GET_CONTENT_MISSION_BY_MISSION,
	API_MANAGE_MISSIONS,
} from '../../../../utils/constantsAdmin'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { toggleCollapse } from '../../../../utils/general_functions'
import { MissionBodyCollapse } from './MissionBodyCollapse'
import AlertContext from '../../../../context/editor/AlertContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { returnProcessUrl } from '../../../../utils/general_functions_forms'
import { FormExchangeMission } from './FormExchangeMission'

export const MissionCollapse = ({
	objectMission,
	setRefreshMissions,
	idSubWorldSceneNpcAppearance,
	idScene,
	activeCollapseMission,
	setActiveCollapseMission,
	setRefreshObjectNpc,
	setRefreshNpcsScene,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// OBJECT
	const [stateObjectMission, setStateObjectMission] = useState(undefined)
	useEffect(() => {
		setStateObjectMission(objectMission)
	}, [])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE MISSION
	const updateMission = async () => {
		setTitleModal(
			t('pages.editor.components.mission_collapse.update_mission')
		)
		setContentModal(
			<FormMissions
				idSubWorldSceneNpcAppearance={idSubWorldSceneNpcAppearance}
				objectMission={stateObjectMission}
				setStateObjectMission={setStateObjectMission}
				setRefreshMissions={setRefreshMissions}
				typeMission={stateObjectMission.challenge.category}
				setRefreshObjectNpc={setRefreshObjectNpc}
			/>
		)
		openModal()
	}

	// DELETE MISSION
	const deleteMission = async () => {
		// LIST CONTENT MISSIONS
		let arrayListContentMission = await fetchContentMissions(
			API_GET_CONTENT_MISSION_BY_MISSION +
				stateObjectMission.id_mission +
				'/'
		)
		// DELETE LIST MISSIONS
		let statusDeleteContentMission = true
		for (let i = 0; i < arrayListContentMission.length; i++) {
			if (i < 5) {
				let status = await deleteContentMission(
					arrayListContentMission[i].id_mission_content_add_ons,
					arrayListContentMission[i].mission_content
						.id_mission_content
				)
				statusDeleteContentMission = status
				if (!status) {
					alert(t('errors.error_deleting_content'))
				}
			}
		}
		if (statusDeleteContentMission) {
			// DELETE MISSION
			const result_data = await axiosSupreme(
				'delete',
				API_MANAGE_MISSIONS + objectMission.id_mission + '/',
				undefined
			)
			if (result_data.hasOwnProperty('response')) {
				openDelete()
				setTypeMessage('error')
				setMessageAlert(t('common.cannot_delete'))
				showAlert()
			} else {
				setRefreshMissions((prev) => !prev)
				setRefreshObjectNpc((prev) => !prev)
				openDelete()
			}
		}
	}

	// EXCHANGE MISSION
	const exchangeMission = () => {
		setTitleModal(
			t('pages.editor.components.mission_collapse.exchange_mission')
		)
		setContentModal(
			<FormExchangeMission
				objMission={objectMission}
				setRefreshNpcsScene={setRefreshNpcsScene}
			/>
		)
		openModal()
	}

	const fetchContentMissions = async (url, arrayListContentMission = []) => {
		url = returnProcessUrl(url)
		try {
			let result_data = await axiosSupreme('get', url, undefined)
			arrayListContentMission = [
				...arrayListContentMission,
				...result_data.results,
			]

			if (result_data.next) {
				arrayListContentMission = await fetchContentMissions(
					result_data.next,
					arrayListContentMission
				)
			}
		} catch (error) {
			console.error('Error:', error)
		}
		return arrayListContentMission
	}

	const deleteContentMission = async (
		id_mission_content_add_ons,
		id_mission_content
	) => {
		const result_data = await axiosSupreme(
			'delete',
			API_CONTENT_MISSION_ADD_ONS + id_mission_content_add_ons + '/',
			undefined
		)
		if (!result_data.hasOwnProperty('response')) {
			const result_data_mission_content = await axiosSupreme(
				'delete',
				API_CONTENT_MISSION + id_mission_content + '/',
				undefined
			)
			if (!result_data_mission_content.hasOwnProperty('response')) {
				return true
			} else {
				return false
			}
		} else {
			return false
		}
	}

	return (
		<>
			{stateObjectMission !== undefined ? (
				<>
					<div className='classunkown collapseStylesBtn'>
						<div className='arrowOpenCollapse'>
							{activeCollapseMission ===
							objectMission.id_mission ? (
								<FaChevronUp />
							) : (
								<FaChevronDown />
							)}
						</div>
						<div
							className='nameAndUsable title'
							onClick={() =>
								toggleCollapse(
									activeCollapseMission,
									setActiveCollapseMission,
									objectMission.id_mission
								)
							}>
							<h2>
								{stateObjectMission.order + '. '}
								{stateObjectMission.challenge.category === 'l'
									? t(
											'pages.editor.components.mission_collapse.class'
									  )
									: null}
								{stateObjectMission.challenge.category === 'o'
									? t(
											'pages.editor.components.mission_collapse.lost_object'
									  )
									: null}
								{stateObjectMission.challenge.category === 'g'
									? t(
											'pages.editor.components.mission_collapse.game'
									  )
									: null}
								{stateObjectMission.challenge.category === 'ee'
									? t(
											'pages.editor.components.mission_collapse.easter_egg'
									  )
									: null}
								{'- ' + stateObjectMission.description}
							</h2>
						</div>
						<div className='options'>
							<button
								className='editorBtnActionDelete'
								onClick={() => {
									setFunctionDelete(() => deleteMission)
									setMessage(
										t(
											'pages.editor.components.mission_collapse.confirm_deleting_1'
										) +
											objectMission.description +
											'?'
									)
									openDelete()
								}}>
								<p>
									<BsFillTrashFill />{' '}
								</p>
							</button>
							<button
								className='editorBtnActionEdit'
								onClick={updateMission}>
								<p>
									<BsFillPencilFill />
								</p>
							</button>
							<button
								className='editorBtnActionExchange'
								onClick={exchangeMission}>
								<p>
									<FaArrowsTurnToDots />
								</p>
							</button>
						</div>
					</div>
					{activeCollapseMission === objectMission.id_mission && (
						<MissionBodyCollapse
							stateObjectMission={stateObjectMission}
							idSubWorldSceneNpcAppearance={
								idSubWorldSceneNpcAppearance
							}
							idScene={idScene}
						/>
					)}
				</>
			) : null}
		</>
	)
}
