import { useEffect, useState } from 'react'
import { NpcBodyCollapse } from './NpcBodyCollapse'
import {
	API_MANAGE_DIALOGUES_BY_NPC_COMPOSE,
	API_MANAGE_MISSIONS_BY_NPC_APPEARANCE,
} from '../../../../utils/constantsAdmin'
import {
	FaChevronDown,
	FaChevronUp,
	FaExclamationTriangle,
} from 'react-icons/fa'
import { toggleCollapse } from '../../../../utils/general_functions'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const NpcCollapse = ({
	itemNpc,
	setRefreshNpcsScene,
	idScene,
	activeCollapseNpc,
	setActiveCollapseNpc,
}) => {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// SET OBJECT NPC
	const [stateObjectNpc, setStateObjectNpc] = useState(undefined)
	// SET REFRESH NPC
	const [refreshObjectNpc, setRefreshObjectNpc] = useState(false)

	// STATUS MISSION
	const [statusMission, setStatusMission] = useState(true)
	const [statusGreeting, setStatusGreeting] = useState(false)

	useEffect(() => {
		setStateObjectNpc(itemNpc)
		returnStatusMission()
		returnStatusDialogue()
	}, [itemNpc, refreshObjectNpc])

	const returnStatusMission = async () => {
		const result_data = await axiosSupreme(
			'get',
			API_MANAGE_MISSIONS_BY_NPC_APPEARANCE +
				itemNpc.id_sub_world_scene_npc_appearance +
				'/',
			undefined
		)
		if (result_data.results.length === 0) {
			setStatusMission(false)
		} else {
			setStatusMission(true)
		}
	}

	const returnStatusDialogue = async () => {
		for (const key of Object.keys(itemNpc.composes)) {
			if (itemNpc.composes[key].npc_state === 'greeting') {
				const result_data = await axiosSupreme(
					'get',
					API_MANAGE_DIALOGUES_BY_NPC_COMPOSE +
						itemNpc.composes[key]
							.id_npc_compose_sub_world_scene_npc_appearance +
						'/',
					undefined
				)
				if (result_data.results.length > 0) {
					setStatusGreeting(true)
				}
			}
		}
	}

	// const DeleteNpcScene = async () => {
	// 	const confirmDelete = window.confirm(
	// 		t(
	// 			'pages.editor.components.npc_collapse_appearance.confirm_deleting_1'
	// 		) +
	// 			stateObjectNpc.name +
	// 			t(
	// 				'pages.editor.components.npc_collapse_appearance.confirm_deleting_2'
	// 			)
	// 	)
	// 	if (confirmDelete) {
	// 		Object.keys(stateObjectNpc.composes).map(async (key) => {
	// 			await axiosSupreme(
	// 				'delete',
	// 				API_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE +
	// 					stateObjectNpc.composes[key]
	// 						.id_npc_compose_sub_world_scene_npc_appearance +
	// 					'/',
	// 				undefined
	// 			)
	// 		})
	// 		await axiosSupreme(
	// 			'delete',
	// 			API_SUB_WORLD_SCENE_NPC_APPEARANCE +
	// 				stateObjectNpc.id_sub_world_scene_npc_appearance +
	// 				'/',
	// 			undefined
	// 		)
	// 	}
	// }

	return (
		<>
			{stateObjectNpc !== undefined ? (
				<div className='adminComponents'>
					<div className='adminComponents__button collapseStylesBtn'>
						<div className='arrowOpenCollapse'>
							{activeCollapseNpc ===
							stateObjectNpc.id_sub_world_scene_npc_appearance ? (
								<FaChevronUp />
							) : (
								<FaChevronDown />
							)}
						</div>
						<div
							className='adminComponents__button__title title'
							onClick={() =>
								toggleCollapse(
									activeCollapseNpc,
									setActiveCollapseNpc,
									stateObjectNpc.id_sub_world_scene_npc_appearance
								)
							}>
							<h2
								className={
									!statusMission || !statusGreeting
										? 'error'
										: ''
								}
								title={
									!statusMission
										? t(
												'pages.editor.components.npc_collapse_appearance.npc_none_mission_assignment'
										  )
										: !statusGreeting
										? t(
												'pages.editor.components.npc_collapse_appearance.npc_none_greeting_assignment'
										  )
										: ''
								}>
								{stateObjectNpc.coordinates +
									'\t-\t' +
									stateObjectNpc.name}
								{!statusMission || !statusGreeting ? (
									<p>
										<FaExclamationTriangle
											title={
												!statusMission
													? t(
															'pages.editor.components.npc_collapse_appearance.npc_none_mission_assignment'
													  )
													: !statusGreeting
													? t(
															'pages.editor.components.npc_collapse_appearance.npc_none_greeting_assignment'
													  )
													: ''
											}
										/>
									</p>
								) : null}
							</h2>
						</div>
						<div className='adminComponents__button__options options'>
							{/* <button className='btnDelete' 
                                onClick={() => {
                                    setFunctionDelete(() => DeleteDecoration)
                                    setMessage("¿Está seguro de que desea eliminar la decoración: " + stateObjectDecoration.animation.name +" de la escena: ?")
                                    openDelete()
                                }}
                            >
                                <p><BsFillTrashFill/></p></button> */}
						</div>
					</div>
					{activeCollapseNpc ===
						stateObjectNpc.id_sub_world_scene_npc_appearance && (
						<NpcBodyCollapse
							key={stateObjectNpc.id_npc + 'Collapse'}
							idScene={idScene}
							stateObjectNpc={stateObjectNpc}
							setRefreshNpcsScene={setRefreshNpcsScene}
							setRefreshObjectNpc={setRefreshObjectNpc}
							statusGreeting={statusGreeting}
							setStatusGreeting={setStatusGreeting}
						/>
					)}
				</div>
			) : null}
		</>
	)
}
