import '../styles/MobileRedirection.scss'
import ImageOnFlex1 from './fragments/ImageOnFlex1'
import loroImage from '../assets/images/aspectRation.png'
import googlePlayImage from '../assets/images/mobileStore/googlePlay/english.png'
import appStoreImage from '../assets/images/mobileStore/appStore/english.svg'

const MobileRedirection = () => {
	return (
		<section className='mobile__redirection'>
			<h1>¡DESCARGA LA APP YA MISMO!</h1>
			<ImageOnFlex1 src={loroImage} />
			<div className='mobile__redirection__buttons'>
				<a
					href='https://play.google.com/store/apps/details?id=com.scandinaviansmartsolutions.Teo_Loro&pcampaignid=web_share'
					target='_blank'
					rel='noreferrer noopener'>
					<img
						src={googlePlayImage}
						alt='google play'
						decoding='async'
					/>
				</a>
				<a
					href='https://apps.apple.com/bo/app/teo-loro/id6479323281'
					target='_blank'
					rel='noreferrer noopener'>
					<img
						src={appStoreImage}
						alt='apple store'
						decoding='async'
					/>
				</a>
			</div>
		</section>
	)
}

export default MobileRedirection
