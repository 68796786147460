import { useState } from 'react'
import { ListNpcOutfit } from './NPCOutfit/ListNpcOutfit'
import { ListNpcPoses } from './NPCPoses/ListNpcPoses'
import { ListNpcState } from './NPCState/ListNpcState'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

export const NpcStateCollapse = () => {
	const { t } = useTranslation()

	// COLLAPSE
	const [isCollapsed, setIsCollapsed] = useState(true)
	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed)
	}

	// NAV TAB
	const [activeTab, setActiveTab] = useState('outfits')
	const handleTabClick = (tabName) => {
		setActiveTab(tabName)
	}

	return (
		<div className='collapseNpc '>
			<>
				<div className='collapseNpc__button collapseStylesBtn'>
					{!isCollapsed ? (
						<div className='arrowOpenCollapse'>
							<FaChevronUp />{' '}
						</div>
					) : (
						<div className='arrowOpenCollapse'>
							{' '}
							<FaChevronDown />
						</div>
					)}
					<div
						className='collapseNpc__button__title'
						onClick={toggleCollapse}>
						<h2>
							{t(
								'pages.editor.components.npc_state_collapse.characteristics_for_npcs'
							)}
						</h2>
					</div>
				</div>
				{!isCollapsed && (
					<div className='stateNpcContent collapseStylesBody'>
						<div className='stateNpcContent__container'>
							<div className='contentNpcs'>
								<div className='contentNpcs__container'>
									<nav>
										<ul>
											<li
												className={`nav-item ${
													activeTab === 'outfits'
														? 'active'
														: ''
												}`}
												onClick={() =>
													handleTabClick('outfits')
												}>
												<a className='nav-link'>
													{t(
														'pages.editor.components.npc_state_collapse.suits'
													)}
												</a>
											</li>
											<li
												className={`nav-item ${
													activeTab === 'poses'
														? 'active'
														: ''
												}`}
												onClick={() =>
													handleTabClick('poses')
												}>
												<a className='nav-link'>
													{t(
														'pages.editor.components.npc_state_collapse.poses'
													)}
												</a>
											</li>
											<li
												className={`nav-item ${
													activeTab === 'state'
														? 'active'
														: ''
												}`}
												onClick={() =>
													handleTabClick('state')
												}>
												<a className='nav-link'>
													{t(
														'pages.editor.components.npc_state_collapse.state'
													)}
												</a>
											</li>
										</ul>
									</nav>
									<div>
										{activeTab === 'outfits' && (
											<ListNpcOutfit />
										)}
										{activeTab === 'poses' && (
											<ListNpcPoses />
										)}
										{activeTab === 'state' && (
											<ListNpcState />
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		</div>
	)
}
