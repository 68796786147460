import { useContext, useEffect, useState } from 'react'
import { FormOutfits } from './FormOutfits'
import ModalContext from '../../../../../context/editor/ModalContext'
import { Pagination } from '../../../searchAndPagination/Pagination'
import { NpcOutfitContent } from './NpcOutfitContent'
import { SearchCamp } from '../../../searchAndPagination/SearchCamp'
import { API_MANAGE_NPC_OUTFITS } from '../../../../../utils/constantsAdmin'
import { LoadingIcon } from '../../../LoadingIcon'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListNpcOutfit = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// REFRESH OUTFITS
	const [refreshOutfitsNpc, setRefreshOutfitsNpc] = useState(false)

	// DATA
	const [data, setData] = useState(undefined)

	// GET ALL OUTFITS
	useEffect(() => {
		getData()
	}, [refreshOutfitsNpc])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				API_MANAGE_NPC_OUTFITS
			)
			setData(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddOutfit = () => {
		setTitleModal(t('pages.editor.components.list_npc_outfit.add_suit'))
		setContentModal(
			<FormOutfits setRefreshOutfitsNpc={setRefreshOutfitsNpc} />
		)
		openModal()
	}

	return (
		<div className='nav-content'>
			<div className='npcDataContent__btnAdd'>
				<h1>{t('pages.editor.components.list_npc_outfit.suits')}</h1>
				<div className='boxBtnAdd'>
					<button
						className='editorBtnActionAdd'
						onClick={OpenAddOutfit}>
						{t('common.add')}
					</button>
				</div>
			</div>
			{data !== undefined ? (
				<>
					{data.results.length > 0 || data === 'search' ? (
						<>
							<SearchCamp
								setData={setData}
								setRefreshDataList={setRefreshOutfitsNpc}
								url={API_MANAGE_NPC_OUTFITS}
								search={search}
								setSearch={setSearch}
							/>
							{data === 'search' ? (
								<p className='noSelect'>
									{t(
										'pages.editor.components.list_npc_outfit.none_suits_found'
									)}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t(
								'pages.editor.components.list_npc_outfit.none_suit_loaded'
							)}
						</p>
					)}
					{data !== 'search' ? (
						<>
							{data.results.map((data_item, index) => (
								<NpcOutfitContent
									key={data_item.id_outfit_npc}
									objectOutfit={data_item}
									setRefreshOutfitsNpc={setRefreshOutfitsNpc}
								/>
							))}
							<Pagination
								data={data}
								setData={setData}></Pagination>
						</>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</div>
	)
}
