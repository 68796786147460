import React, { useContext, useEffect, useRef, useState } from 'react'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import { API_MANAGE_DIALOGUES } from '../../../../utils/constantsAdmin'
import SaveButton from '../../SaveButton'
import {
	onInputChangeDefault,
	onInputChangeSound,
	processErrors,
} from '../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../LoadingIcon'
import { handleKeyPressInteger } from '../../../../utils/general_functions_forms'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const FormDialogues = ({
	id_npc_compose_sub_world_scene_npc_appearance,
	stateObjectDialogue,
	setStateObjectDialogue,
	setRefreshDialogues,
}) => {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [text, setText] = useState('')
	const [textTraduction, setTextTraduction] = useState('')
	const [order, setOrder] = useState('')
	const audioFile = useRef(null)
	const [
		npcComposeSubWorldSceneNpcAppearance,
		setNpcComposeSubWorldSceneNpcAppearance,
	] = useState('')

	// ERROR FOR FORM
	const [errorAudio, setErrorAudio] = useState('')

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setText('')
		setTextTraduction('')
		setOrder('')
		audioFile.current.value = null
	}

	// SET WORLD WHEN UPDATE
	useEffect(() => {
		setNpcComposeSubWorldSceneNpcAppearance(
			id_npc_compose_sub_world_scene_npc_appearance
		)
		if (stateObjectDialogue !== undefined) {
			setText(stateObjectDialogue.text)
			setTextTraduction(stateObjectDialogue.text_traduction)
			setOrder(stateObjectDialogue.order)
		} else {
			clearFields()
		}
	}, [stateObjectDialogue])

	// SUBMIT FORM DIALOGUE
	const onSubmitDialogue = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('text', event.target.elements.text.value)
		formData.append(
			'text_traduction',
			event.target.elements.text_traduction.value
		)
		formData.append('order', event.target.elements.order.value)
		formData.append(
			'npc_compose_sub_world_scene_npc_appearance',
			npcComposeSubWorldSceneNpcAppearance
		)
		if (event.target.elements.audio_file.value) {
			formData.append(
				'audio_file',
				event.target.elements.audio_file.files[0]
			)
		}

		if (stateObjectDialogue === undefined) {
			// SAVE
			manageDialogues(API_MANAGE_DIALOGUES, formData, 'add')
		} else {
			// UPDATE
			manageDialogues(
				API_MANAGE_DIALOGUES + stateObjectDialogue.id_dialogue + '/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE DIALOGUE
	const manageDialogues = async (url, obj, action) => {
		try {
			// PROCESS DATA
			let result_data =
				action === 'add'
					? await axiosSupreme('post', url, obj)
					: await axiosSupreme('patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add' ? t('common.added') : t('common.modified')
			)
			showAlert()
			if (action === 'add') setRefreshDialogues((prev) => !prev)
			if (action === 'update') setStateObjectDialogue(result_data)
			setStateLoading(false)
			openModal()
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	return (
		<>
			<form onSubmit={onSubmitDialogue}>
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_dialogues.order_and_placeholder'
						)}
					</label>
					<input
						name='order'
						className='admin__container__inputs__in'
						id='order'
						type='number'
						placeholder={t(
							'pages.editor.components.form_dialogues.order_and_placeholder'
						)}
						autoComplete='off'
						value={order}
						onChange={(event) =>
							onInputChangeDefault(event, setOrder)
						}
						onKeyPress={(event) => handleKeyPressInteger(event)}
						required></input>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_dialogues.spanish_text'
						)}
					</label>
					<textarea
						name='text'
						className='admin__container__inputs__in'
						id='text'
						type='text'
						placeholder={t(
							'pages.editor.components.form_dialogues.text_placeholder'
						)}
						autoComplete='off'
						value={text}
						onChange={(event) =>
							onInputChangeDefault(event, setText)
						}
						required></textarea>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t(
							'pages.editor.components.form_dialogues.finnish_text'
						)}
					</label>
					<textarea
						name='text_traduction'
						className='admin__container__inputs__in'
						id='text_traduction'
						type='text'
						placeholder={t(
							'pages.editor.components.form_dialogues.text_trasnlation'
						)}
						autoComplete='off'
						value={textTraduction}
						onChange={(event) =>
							onInputChangeDefault(event, setTextTraduction)
						}
						required></textarea>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t('pages.editor.components.form_dialogues.audio')}
					</label>
					{stateObjectDialogue !== undefined ? (
						<>
							{stateObjectDialogue.audio_file !== null ? (
								<label htmlFor=''>
									{t('common.current_file')}
									<a
										href={stateObjectDialogue.audio_file}
										target='_blank'
										rel='noopener noreferrer'>
										{t('common.open')}
									</a>
								</label>
							) : null}
						</>
					) : null}
					<input
						name='audio_file'
						className='admin__container__inputs__in'
						id='audio_file'
						type='file'
						ref={audioFile}
						autoComplete='off'
						onChange={(event) =>
							onInputChangeSound(event, setErrorAudio, audioFile)
						}
						accept='audio/*'
						required={stateObjectDialogue === undefined}></input>
					{errorAudio && <p>{errorAudio}</p>}
				</div>

				{stateLoading === false ? (
					<div className='admin__container__boxBtn'>
						<SaveButton></SaveButton>
					</div>
				) : (
					<LoadingIcon />
				)}
			</form>
		</>
	)
}
