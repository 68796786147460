import { useContext, useEffect, useState } from 'react'
import { BsFillTrashFill } from 'react-icons/bs'
import { NpcBodyComposeCollapse } from './NpcBodyComposeCollapse'

import {
	API_MANAGE_DIALOGUES_BY_NPC_COMPOSE,
	API_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE,
	API_SUB_WORLD_SCENE_NPC_APPEARANCE,
} from '../../../../utils/constantsAdmin'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import AlertContext from '../../../../context/editor/AlertContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { useTranslation } from 'react-i18next'

export const NpcComposeCollapse = ({
	itemCompose,
	counterComposes,
	idSubWorldSceneNpcAppearance,
	setRefreshNpcsScene,
	statusGreeting,
	setStatusGreeting,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// COLLAPSE
	const [isCollapsed, setIsCollapsed] = useState(true)
	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed)
	}

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	const deleteCompose = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_NPC_SUB_WORLD_SCENE_NPC_APPEARANCE +
				itemCompose.id_npc_compose_sub_world_scene_npc_appearance +
				'/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(t('common.cannot_delete'))
			showAlert()
		} else {
			if (counterComposes === 1) {
				await axiosSupreme(
					'delete',
					API_SUB_WORLD_SCENE_NPC_APPEARANCE +
						idSubWorldSceneNpcAppearance +
						'/',
					undefined
				)
				if (result_data.hasOwnProperty('response')) {
					openDelete()
					setTypeMessage('error')
					setMessageAlert(t('common.cannot_delete'))
					showAlert()
				} else {
					setRefreshNpcsScene((prev) => !prev)
					openDelete()
				}
			} else {
				setRefreshNpcsScene((prev) => !prev)
				openDelete()
			}
		}
	}

	useEffect(() => {
		returnStatusDialogue()
	}, [])

	const returnStatusDialogue = async () => {
		const result_data = await axiosSupreme(
			'get',
			API_MANAGE_DIALOGUES_BY_NPC_COMPOSE +
				itemCompose.id_npc_compose_sub_world_scene_npc_appearance +
				'/',
			undefined
		)
		if (result_data.results.length > 0) {
			setStatusGreeting(true)
		}
	}

	return (
		<div className='npcSceneComponent'>
			<div className='npcSceneComponent__button collapseStylesBtn'>
				{!isCollapsed === true ? (
					<div className='arrowOpenCollapse'>
						<FaChevronUp />{' '}
					</div>
				) : (
					<div className='arrowOpenCollapse'>
						{' '}
						<FaChevronDown />
					</div>
				)}
				<div
					className='npcSceneComponent__button__title title'
					onClick={toggleCollapse}>
					<h2
						className={
							itemCompose.npc_state === 'greeting' &&
							!statusGreeting
								? 'error'
								: ''
						}>
						{itemCompose.name}
					</h2>
				</div>
				<div className='npcSceneComponent__button__options options'>
					<button
						className='editorBtnActionDelete'
						onClick={() => {
							setFunctionDelete(() => deleteCompose)
							setMessage(
								t(
									'pages.editor.components.npc_compose_collapse.confirm_deleting_1'
								)
							)
							openDelete()
						}}>
						<p>
							<BsFillTrashFill />{' '}
						</p>
					</button>
				</div>
			</div>
			{!isCollapsed && (
				<NpcBodyComposeCollapse
					key={itemCompose.id_npc + 'Collapse'}
					itemCompose={itemCompose}
					setStatusGreeting={setStatusGreeting}
				/>
			)}
		</div>
	)
}
