import React, { useContext, useState } from 'react'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { API_MANAGE_GAME_LANGUAGE } from '../../../../utils/constantsAdmin'
import AlertContext from '../../../../context/editor/AlertContext'
import { useTranslation } from 'react-i18next'
import {
	onInputChangeDefault,
	processErrors,
} from '../../../../utils/general_functions_forms'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import { MdCancel } from 'react-icons/md'

//Flags
import suomiFlag from '../../../../assets/Flags/bandera-finlandia.png'
import spainFlag from '../../../../assets/Flags/bandera-espana.png'
import deutschFlag from '../../../../assets/Flags/bandera-alemania.png'
import francaisFlag from '../../../../assets/Flags/bandera-francia.png'
import svenskaFlag from '../../../../assets/Flags/bandera-suecia.png'
import englishFlag from '../../../../assets/Flags/bandera-inglaterra.webp'
import firstGrade from '../../../../assets/Flags/number_one.png'
import fifthGrade from '../../../../assets/Flags/number_five.webp'

export const ItemGameLanguage = ({
	type,
	objectGameLanguage,
	selectedGameLanguage,
	setSelectedGameLanguage,
	setRender,
}) => {
	console.log('VALOR DE LLEGADA: ', type)
	console.log('VALOR DE LLEGADA: ', objectGameLanguage)
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	const listAvailableGameLanguage = [
		'english',
		'español',
		'soumi',
		'francais',
		'deutsch',
		'svenska',
		'first_grade',
		'fifth_grade',
	]

	const [baseLanguage, setBaseLanguage] = useState(
		type === 'existing' ? objectGameLanguage.base_language : ''
	)
	const [languageToLearn, setLanguageToLearn] = useState(
		type === 'existing' ? objectGameLanguage.language_to_learn : ''
	)
	const [isEditing, setIsEditing] = useState(
		type === 'existing' ? false : true
	)

	const selectLevelLanguage = () => {
		if (type === 'existing' && !isEditing) {
			setSelectedGameLanguage(objectGameLanguage.id_game_language)
		}
	}

	const onSubmitForm = async (e) => {
		try {
			e.preventDefault()
			let url =
				type === 'existing'
					? API_MANAGE_GAME_LANGUAGE +
					  objectGameLanguage.id_game_language +
					  '/'
					: API_MANAGE_GAME_LANGUAGE
			let method = type === 'existing' ? 'patch' : 'post'
			const dataSend = new FormData(e.target)
			await axiosSupreme(method, url, dataSend)
			setRender((prev) => !prev)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
	}

	const onEditGameLanguage = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setIsEditing(true)
	}

	const onCancelEdit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setIsEditing(false)
	}

	const onDeleteLevelLanguage = async (e) => {
		e.preventDefault()
		e.stopPropagation()
		try {
			e.preventDefault()
			let url =
				API_MANAGE_GAME_LANGUAGE +
				objectGameLanguage.id_game_language +
				'/'
			await axiosSupreme('delete', url, null)
			setRender((prev) => !prev)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
	}

	return (
		<>
			<div
				className={`containerListGameLanguagesListItem ${
					type === 'existing' &&
					selectedGameLanguage === objectGameLanguage.id_game_language
						? 'selectedGameLanguage'
						: ''
				} `}
				onClick={selectLevelLanguage}>
				{!isEditing ? (
					<>
						<button
							className='containerListGameLanguagesListItemEdit'
							onClick={(e) => onEditGameLanguage(e)}>
							<BsFillPencilFill />
						</button>
						<button
							className='containerListGameLanguagesListItemDelete'
							onClick={(e) => onDeleteLevelLanguage(e)}>
							<BsFillTrashFill />
						</button>
						<picture className='start__subscriptions__container'>
							<img
								className='start__subscriptions__container__flag1'
								src={
									objectGameLanguage.language_to_learn ===
									'español'
										? spainFlag
										: objectGameLanguage.language_to_learn ===
										  'soumi'
										? suomiFlag
										: objectGameLanguage.language_to_learn ===
										  'deutsch'
										? deutschFlag
										: objectGameLanguage.language_to_learn ===
										  'francais'
										? francaisFlag
										: objectGameLanguage.language_to_learn ===
										  'svenska'
										? svenskaFlag
										: objectGameLanguage.language_to_learn ===
										  'english'
										? englishFlag
										: objectGameLanguage.language_to_learn ===
										  'first_grade'
										? firstGrade
										: objectGameLanguage.language_to_learn ===
										  'fifth_grade'
										? fifthGrade
										: ''
								}
								alt={`${objectGameLanguage.language_to_learn}flag`}
								loading='lazy'
								decoding='async'
							/>
							<img
								className='start__subscriptions__container__flag2'
								src={
									objectGameLanguage.base_language ===
									'español'
										? spainFlag
										: objectGameLanguage.base_language ===
										  'soumi'
										? suomiFlag
										: objectGameLanguage.base_language ===
										  'deutsch'
										? deutschFlag
										: objectGameLanguage.base_language ===
										  'francais'
										? francaisFlag
										: objectGameLanguage.base_language ===
										  'svenska'
										? svenskaFlag
										: objectGameLanguage.base_language ===
										  'english'
										? englishFlag
										: objectGameLanguage.base_language ===
										  'first_grade'
										? firstGrade
										: objectGameLanguage.base_language ===
										  'fifth_grade'
										? fifthGrade
										: ''
								}
								alt={`${objectGameLanguage.language_to_learn}flag`}
								loading='lazy'
								decoding='async'
							/>
						</picture>
						<p>{`${objectGameLanguage.base_language} -> ${objectGameLanguage.language_to_learn}`}</p>
					</>
				) : (
					<>
						{type === 'existing' && (
							<button
								className='containerListGameLanguagesListItemEdit'
								onClick={(e) => onCancelEdit(e)}>
								<MdCancel />
							</button>
						)}
						<form onSubmit={onSubmitForm}>
							<div className='containerListGameLanguagesListItemFormGroup'>
								<label htmlFor='id_base_language'>
									Lenguage a Aprender
								</label>
								<select
									name='base_language'
									id='id_base_language'
									value={baseLanguage}
									onChange={(event) =>
										onInputChangeDefault(
											event,
											setBaseLanguage
										)
									}
									required>
									{listAvailableGameLanguage.map(
										(item, index) => (
											<option key={item} value={item}>
												{item}
											</option>
										)
									)}
								</select>
							</div>
							<div className='containerListGameLanguagesListItemFormGroup'>
								<label htmlFor='id_base_language'>
									Lenguage Base
								</label>
								<select
									name='language_to_learn'
									id='language_to_learn'
									value={languageToLearn}
									onChange={(event) =>
										onInputChangeDefault(
											event,
											setLanguageToLearn
										)
									}
									required>
									{listAvailableGameLanguage.map(
										(item, index) => (
											<option key={item} value={item}>
												{item}
											</option>
										)
									)}
								</select>
							</div>
							<button
								className='editorBtnActionAdd'
								type='submit'>
								Guardar
							</button>
						</form>
					</>
				)}
			</div>
		</>
	)
}
