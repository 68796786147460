import { useContext, useEffect, useState } from 'react'
import { API_MANAGE_STORE_CATEGORY } from '../../../../../utils/constantsAdmin'
import ModalContext from '../../../../../context/editor/ModalContext'
import { FormCategories } from './FormCategories'
import { SearchCamp } from '../../../searchAndPagination/SearchCamp'
import { CategoriesContent } from './CategoriesContent'
import { Pagination } from '../../../searchAndPagination/Pagination'
import { LoadingIcon } from '../../../LoadingIcon'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListCategories = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// REFRESH CATEGORIES
	const [refreshCategories, setRefreshCategories] = useState(false)

	// DATA
	const [data, setData] = useState(undefined)

	// GET ALL CATEGORIES
	useEffect(() => {
		const getData = async () => {
			try {
				const result_data = await axiosSupreme(
					'get',
					API_MANAGE_STORE_CATEGORY
				)
				setData(result_data)
			} catch (e) {
				console.log('Error get Data: ', e)
			}
		}
		getData()
	}, [refreshCategories])

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddCategory = () => {
		setTitleModal(t('pages.editor.components.list_categories.add_category'))
		setContentModal(
			<FormCategories setRefreshCategories={setRefreshCategories} />
		)
		openModal()
	}

	return (
		<div className='storeAdmin__container'>
			<div className='storeAdmin__container__btnAdd'>
				<div className='boxBtnAdd'>
					<button
						className='editorBtnActionAdd'
						onClick={OpenAddCategory}>
						{t('common.add')}
					</button>
				</div>
			</div>
			{data !== undefined ? (
				<>
					{data === 'search' || data.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setData}
								setRefreshDataList={setRefreshCategories}
								url={API_MANAGE_STORE_CATEGORY}
								search={search}
								setSearch={setSearch}
							/>
							{data === 'search' ? (
								<p className='noSelect'>
									{t(
										'pages.editor.components.list_categories.none_category_found'
									)}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t(
								'pages.editor.components.list_categories.none_category_loaded'
							)}
						</p>
					)}
					{data !== 'search' ? (
						<>
							{data.results.map((data_item) => (
								<CategoriesContent
									key={data_item.id_item_category}
									objectCategory={data_item}
									setRefreshCategories={setRefreshCategories}
								/>
							))}
							<Pagination
								data={data}
								setData={setData}></Pagination>
						</>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</div>
	)
}
