import { useContext, useEffect, useState } from 'react'
import { API_MANAGE_MISSIONS_BY_NPC_APPEARANCE } from '../../../../utils/constantsAdmin'
import { FormMissions } from './FormMissions'
import ModalContext from '../../../../context/editor/ModalContext'
import { MissionCollapse } from './MissionCollapse'
import { Pagination } from '../../searchAndPagination/Pagination'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import {
	FaBookReader,
	FaBrain,
	FaChevronDown,
	FaChevronUp,
	FaExclamationCircle,
	FaGamepad,
} from 'react-icons/fa'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { LoadingIcon } from '../../LoadingIcon'
import { useTranslation } from 'react-i18next'

export const ListMissions = ({
	idSubWorldSceneNpcAppearance,
	idScene,
	setRefreshObjectNpc,
	setRefreshNpcsScene,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	//REFRESH MISSIONS
	const [refreshMissions, setRefreshMissions] = useState(false)

	// VISIBLE ADD ALL MISSIONS
	const [visibleAllMissions, setVisibleAllMissions] = useState(false)

	// GET ALL MISSIONS
	const [data, setData] = useState(undefined)
	useEffect(() => {
		getMissions()
	}, [refreshMissions])

	const getMissions = async () => {
		const result_data = await axiosSupreme(
			'get',
			API_MANAGE_MISSIONS_BY_NPC_APPEARANCE +
				idSubWorldSceneNpcAppearance +
				'/',
			undefined
		)
		console.log('RESULT DATA: ', result_data)
		setData(result_data)
		if (result_data.results.length === 0) {
			setVisibleAllMissions(true)
		} else {
			setVisibleAllMissions(false)
		}
	}

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddMissionModal = (typeMission) => {
		setTitleModal(t('pages.editor.components.list_missions.title'))
		setContentModal(
			<FormMissions
				idSubWorldSceneNpcAppearance={idSubWorldSceneNpcAppearance}
				setRefreshMissions={setRefreshMissions}
				typeMission={typeMission}
				setRefreshObjectNpc={setRefreshObjectNpc}
			/>
		)
		openModal()
	}

	// ACTIVECOLLAPSE
	const [activeCollapseMission, setActiveCollapseMission] =
		useState(undefined)

	// COLLAPSE
	const [isCollapsed, setIsCollapsed] = useState(true)
	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed)
	}

	return (
		<>
			<div className='challengeAndGame__button collapseStylesBtn'>
				<div
					className='challengeAndGame__button__title title'
					onClick={toggleCollapse}>
					<div className='arrowOpenCollapse'>
						{!isCollapsed ? <FaChevronUp /> : <FaChevronDown />}
					</div>
					<h2 className={visibleAllMissions ? 'error' : ''}>
						{' '}
						{t('pages.editor.components.list_missions.data')}
					</h2>
				</div>
			</div>

			{!isCollapsed && (
				<div className='challengeAndGameColl collapseStylesBody'>
					<div className='challengeAndGameColl__container'>
						<div className='challengeAndGameContent'>
							<div className='challengeAndGameContent__container'>
								<div className='mission'>
									<div className='mission__btnAdd'>
										<h1>
											{t(
												'pages.editor.components.list_missions.missions'
											)}
										</h1>
										<div className='mission__btnAdd__boxBtnAdd'>
											<>
												<button
													className='btnAdd'
													onClick={() =>
														OpenAddMissionModal('l')
													}>
													<FaBookReader />{' '}
													{t(
														'pages.editor.components.list_missions.code_class'
													)}
												</button>
												<button
													className='btnAdd'
													onClick={() =>
														OpenAddMissionModal('g')
													}>
													<FaGamepad />{' '}
													{t(
														'pages.editor.components.list_missions.code_game'
													)}
												</button>
												<button
													className='btnAdd'
													onClick={() =>
														OpenAddMissionModal(
															'ee'
														)
													}>
													<FaBrain />{' '}
													{t(
														'pages.editor.components.list_missions.code_egg'
													)}
												</button>
												<button
													className='btnAdd'
													onClick={() =>
														OpenAddMissionModal('o')
													}>
													<FaExclamationCircle />{' '}
													{t(
														'pages.editor.components.list_missions.code_lost'
													)}
												</button>
											</>
										</div>
									</div>
									{data !== undefined ? (
										<>
											{data === 'search' ||
											data.results.length > 0 ? (
												<>
													<SearchCamp
														setData={setData}
														setRefreshDataList={
															setRefreshMissions
														}
														url={
															API_MANAGE_MISSIONS_BY_NPC_APPEARANCE +
															idSubWorldSceneNpcAppearance +
															'/'
														}
														search={search}
														setSearch={setSearch}
													/>
													{data === 'search' ? (
														<p className='noSelect'>
															{t(
																'pages.editor.components.list_missions.none_mission'
															)}
														</p>
													) : null}
												</>
											) : null}
											{data !== 'search' ? (
												<div className='mission__content'>
													{data.results.map(
														(data_item, index) => (
															<MissionCollapse
																key={
																	data_item.id_mission
																}
																objectMission={
																	data_item
																}
																setRefreshMissions={
																	setRefreshMissions
																}
																idSubWorldSceneNpcAppearance={
																	idSubWorldSceneNpcAppearance
																}
																idScene={
																	idScene
																}
																activeCollapseMission={
																	activeCollapseMission
																}
																setActiveCollapseMission={
																	setActiveCollapseMission
																}
																setRefreshObjectNpc={
																	setRefreshObjectNpc
																}
																setRefreshNpcsScene={
																	setRefreshNpcsScene
																}
															/>
														)
													)}
													{data.results.length > 0 ? (
														<Pagination
															data={data}
															setData={
																setData
															}></Pagination>
													) : null}
												</div>
											) : null}
										</>
									) : (
										<LoadingIcon />
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
