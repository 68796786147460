import React, { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../../context/editor/ModalContext'
import { API_MANAGE_CHALLENGES } from '../../../../../utils/constantsAdmin'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import { ChallengeBodyCollapse } from './ChallengeBodyCollapse'
import { FormChallenge } from './FormChallenge'
import AlertContext from '../../../../../context/editor/AlertContext'
import DeleteContext from '../../../../../context/editor/DeleteContext'
import { toggleCollapse } from '../../../../../utils/general_functions'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ChallengeCollapse = ({
	objectChallenge,
	setRefreshChallenges,
	activeCollapse,
	setActiveCollapse,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// SET OBJECT CHALLENGE
	const [stateObjectChallenge, setStateObjectChallenge] = useState(undefined)
	useEffect(() => {
		setStateObjectChallenge(objectChallenge)
	}, [objectChallenge])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE CHALLENGE
	const UpdateAnimation = async () => {
		setTitleModal(
			t('pages.editor.components.challenge_collapse.update_challenge')
		)
		setContentModal(
			<FormChallenge
				stateObjectChallenge={stateObjectChallenge}
				setStateObjectChallenge={setStateObjectChallenge}
				setRefreshChallenges={setRefreshChallenges}
			/>
		)
		openModal()
	}

	const DeleteChallenge = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_CHALLENGES + stateObjectChallenge.id_challenge + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(t('common.cannot_delete'))
			showAlert()
		} else {
			setRefreshChallenges((prev) => !prev)
			openDelete()
		}
	}

	return (
		<div className='adminChallengeComponents'>
			{stateObjectChallenge !== undefined ? (
				<>
					<div className='adminChallengeComponents__button collapseStylesBtn'>
						{activeCollapse ===
						stateObjectChallenge.id_challenge ? (
							<div className='arrowOpenCollapse'>
								<FaChevronUp />{' '}
							</div>
						) : (
							<div className='arrowOpenCollapse'>
								{' '}
								<FaChevronDown />
							</div>
						)}
						<div
							className='adminChallengeComponents__button__title title'
							onClick={() =>
								toggleCollapse(
									activeCollapse,
									setActiveCollapse,
									stateObjectChallenge.id_challenge
								)
							}>
							<h2>{stateObjectChallenge.name}</h2>
						</div>
						<div className='adminChallengeComponents__button__options options'>
							<button
								className='editorBtnActionDelete'
								onClick={() => {
									setFunctionDelete(() => DeleteChallenge)
									setMessage(
										t(
											'pages.editor.components.challenge_collapse.confirm_deleting_1'
										) +
											stateObjectChallenge.name +
											'?'
									)
									openDelete()
								}}>
								<p>
									<BsFillTrashFill />{' '}
								</p>
							</button>
							<button
								className='editorBtnActionEdit'
								onClick={UpdateAnimation}>
								<p>
									<BsFillPencilFill />
								</p>
							</button>
						</div>
					</div>
					{activeCollapse === stateObjectChallenge.id_challenge && (
						<ChallengeBodyCollapse
							stateObjectChallenge={stateObjectChallenge}
						/>
					)}
				</>
			) : null}
		</div>
	)
}
