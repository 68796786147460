import React, { useContext, useEffect, useState } from 'react'
import { FormSceneSubWorld } from './FormSceneSubWorld'
import { Pagination } from '../../searchAndPagination/Pagination'
import { SceneSubWorld } from './SceneSubWorld'
import { GET_SCENE_SUBWORLD_BY_SUBWORLD } from '../../../../utils/constantsAdmin'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import ModalContext from '../../../../context/editor/ModalContext'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListSceneSubWorld = ({ idSubWorld }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// REFRESH SCENE SUBWORLDS
	const [refreshSceneSubWorlds, setRefreshSceneSubWorlds] = useState(false)

	// DATA
	const [dataSceneSubWorlds, setDataSceneSubWorlds] = useState(undefined)

	// GET SCENE SUBWORLDS FOR LEVEL SUBWORLDS
	useEffect(() => {
		const getData = async () => {
			try {
				const result_data = await axiosSupreme(
					'get',
					GET_SCENE_SUBWORLD_BY_SUBWORLD + idSubWorld + '/'
				)
				setDataSceneSubWorlds(result_data)
			} catch (e) {
				console.log('Error get Data: ', e)
			}
		}
		getData()
	}, [refreshSceneSubWorlds])

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddScenebWorldModal = () => {
		setTitleModal(
			t('pages.editor.components.list_scene_sub_world.add_scene')
		)
		setContentModal(
			<FormSceneSubWorld
				idSubWorld={idSubWorld}
				setRefreshSceneSubWorlds={setRefreshSceneSubWorlds}
			/>
		)
		openModal()
	}

	return (
		<div className='subWorld__component__scene__ContainerSceneSubWorld'>
			<div className='subWorld__component__scene__ContainerSceneSubWorld__ContainerTitle'>
				<h1>
					{t('pages.editor.components.list_scene_sub_world.scenes')}
				</h1>
				<div className='subWorld__component__scene__ContainerSceneSubWorld__ContainerTitle__BoxButton'>
					<button
						className='editorBtnActionAdd'
						onClick={OpenAddScenebWorldModal}>
						{t('common.add')}
					</button>
				</div>
			</div>

			{dataSceneSubWorlds !== undefined ? (
				<>
					{dataSceneSubWorlds === 'search' ||
					dataSceneSubWorlds.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setDataSceneSubWorlds}
								setRefreshDataList={setRefreshSceneSubWorlds}
								url={
									GET_SCENE_SUBWORLD_BY_SUBWORLD +
									idSubWorld +
									'/'
								}
								search={search}
								setSearch={setSearch}
							/>
							{dataSceneSubWorlds === 'search' ? (
								<p className='noSelect'>
									{t(
										'pages.editor.components.list_scene_sub_world.none_scene_found'
									)}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t(
								'pages.editor.components.list_scene_sub_world.none_scene_assigned'
							)}
						</p>
					)}
					{dataSceneSubWorlds !== 'search' ? (
						<div className='subWorld__component__scene__ContainerSceneSubWorld__ListSceneSubWorld'>
							{dataSceneSubWorlds.results.map((data_item) => (
								<SceneSubWorld
									key={data_item.id_scene_sub_world}
									idSubWorld={idSubWorld}
									objectSceneSubWorld={data_item}
									setRefreshSceneSubWorlds={
										setRefreshSceneSubWorlds
									}
								/>
							))}
							<Pagination
								data={dataSceneSubWorlds}
								setData={setDataSceneSubWorlds}></Pagination>
						</div>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</div>
	)
}
