import { useContext, useEffect, useState } from 'react'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import BodyCollapseSubWorld from './BodyCollapseSubWorld'

import { API_MANAGE_SUBWORLDS } from '../../../../utils/constantsAdmin'
import FormSubWorld from './FormSubWorld'
import ModalContext from '../../../../context/editor/ModalContext'
import AlertContext from '../../../../context/editor/AlertContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { toggleCollapseHistory } from '../../../../utils/general_functions'
import { ManageStorageSubworld } from '../../../../utils/manage_local_storage'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export default function SubWorldComponent({
	idWorld,
	objectSubworld,
	setRefreshSubWorlds,
	activeCollapseSubWorld,
	setActiveCollapseSubWorld,
}) {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// LOCAL STORAGE
	const { setSubworld } = ManageStorageSubworld()

	// SET OBJECT ITEM
	const [stateObjectSubWorld, setStateObjectSubWorld] = useState(undefined)
	useEffect(() => {
		setStateObjectSubWorld(objectSubworld)
	}, [objectSubworld])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE SUBWORLD
	const UpdateSubWorld = async () => {
		setTitleModal(
			t('pages.editor.components.subworld_component.update_subworld')
		)
		setContentModal(
			<FormSubWorld
				idWorld={idWorld}
				stateObjectSubWorld={stateObjectSubWorld}
				setStateObjectSubWorld={setStateObjectSubWorld}
				setRefreshSubWorlds={setRefreshSubWorlds}
			/>
		)
		openModal()
	}

	// DELETE SUBWORLD
	const DeleteLevelSubWorld = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_SUBWORLDS + stateObjectSubWorld.id_sub_world + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(t('common.cannot_delete'))
			showAlert()
		} else {
			setRefreshSubWorlds((prev) => !prev)
			openDelete()
		}
	}

	return (
		<div className='subWorld'>
			{stateObjectSubWorld !== undefined ? (
				<>
					<div className='subWorld__button collapseStylesBtn'>
						{activeCollapseSubWorld ===
						objectSubworld.id_sub_world ? (
							<div className='arrowOpenCollapse'>
								<FaChevronUp />{' '}
							</div>
						) : (
							<div className='arrowOpenCollapse'>
								{' '}
								<FaChevronDown />
							</div>
						)}
						<div
							className='subWorld__button__title title'
							onClick={() =>
								toggleCollapseHistory(
									activeCollapseSubWorld,
									setActiveCollapseSubWorld,
									stateObjectSubWorld.id_sub_world,
									setSubworld
								)
							}>
							<h2 className='titleCom'>
								{stateObjectSubWorld.order}.{' '}
								{stateObjectSubWorld.name}
							</h2>
						</div>
						<div className='subWorld__button__options options'>
							<button
								className='editorBtnActionDelete'
								onClick={() => {
									setFunctionDelete(() => DeleteLevelSubWorld)
									setMessage(
										t(
											'pages.editor.components.subworld_component.confirm_deleting_1'
										) +
											stateObjectSubWorld.name +
											'?'
									)
									openDelete()
								}}>
								<p>
									<BsFillTrashFill />{' '}
								</p>
							</button>
							<button
								className='editorBtnActionEdit'
								onClick={UpdateSubWorld}>
								<p>
									<BsFillPencilFill />
								</p>
							</button>
						</div>
					</div>
					{activeCollapseSubWorld ===
						stateObjectSubWorld.id_sub_world && (
						<BodyCollapseSubWorld
							stateObjectSubWorld={
								stateObjectSubWorld
							}></BodyCollapseSubWorld>
					)}
				</>
			) : null}
		</div>
	)
}
