import { useContext } from 'react'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import ModalContext from '../../../../context/editor/ModalContext'
import { FormLevelLanguage } from './FormLevelLanguage'
import { ObjectLevelLanguageContext } from './ObjectLevelLanguage'
import AlertContext from '../../../../context/editor/AlertContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { ListWorlds } from '../Worlds/ListWorlds'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { API_MANAGE_LEVELS_LANGUAGES } from '../../../../utils/constantsAdmin'
import { useTranslation } from 'react-i18next'

export const ContentLevelLanguage = ({ setRefreshLevels }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { axiosSupreme } = useAuthProvider()

	// CONTEXT OBJECT LANGUAGE
	const { objectLevelLanguage, setObjectLevelLanguage } = useContext(
		ObjectLevelLanguageContext
	)

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE LEVEL
	const UpdateLevel = async () => {
		setTitleModal(t('pages.editor.components.content_level_language.title'))
		setContentModal(
			<FormLevelLanguage
				ObjectLevel={objectLevelLanguage}
				setRefreshLevels={setRefreshLevels}
			/>
		)
		openModal()
	}

	// DELETE LEVEL
	const DeleteLevelLanguage = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_LEVELS_LANGUAGES +
				objectLevelLanguage.id_level_language +
				'/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(t('common.cannot_delete'))
			showAlert()
		} else {
			setRefreshLevels((prev) => !prev)
			setObjectLevelLanguage(undefined)
			openDelete()
		}
	}

	return (
		<>
			<div className='bodyCollapseComponent'>
				<div className='bodyCollapseComponent__description'>
					<div className='bodyCollapseComponent__description__value'>
						<p>
							<strong>
								{t(
									'pages.editor.components.content_level_language.description'
								)}
							</strong>
						</p>
						<p>{objectLevelLanguage.description}</p>
					</div>
					<div className='bodyCollapseComponent__description__options'>
						<button
							className='editorBtnActionDelete'
							onClick={() => {
								setFunctionDelete(() => DeleteLevelLanguage)
								setMessage(
									t(
										'pages.editor.components.content_level_language.confirm_deleting_1'
									) +
										objectLevelLanguage.name +
										'?'
								)
								openDelete()
							}}>
							<p>
								<BsFillTrashFill />{' '}
							</p>
						</button>
						<button
							className='editorBtnActionEdit'
							onClick={UpdateLevel}>
							<p>
								<BsFillPencilFill />
							</p>
						</button>
					</div>
				</div>
				<div className='bodyCollapseComponent__content'>
					<ListWorlds objectLevelLanguage={objectLevelLanguage} />
				</div>
			</div>
		</>
	)
}
