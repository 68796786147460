import { useContext, useEffect, useState } from 'react'
import {
	handleKeyPressInteger,
	onInputChangeDefault,
	processErrors,
} from '../../../../utils/general_functions_forms'
import SaveButton from '../../SaveButton'
import AlertContext from '../../../../context/editor/AlertContext'
import { API_SUB_WORLD_SCENE_NPC_APPEARANCE } from '../../../../utils/constantsAdmin'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const FormCoordinates = ({ itemNpc, setRefreshNpcsScene }) => {
	const { t } = useTranslation()
	const { axiosSupreme } = useAuthProvider()
	// ALERT,  MODAL
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// VALUES FORM
	const [coordinatesXPosition, setCoordinatesXPosition] = useState('')
	const [coordinatesYPosition, setCoordinatesYPosition] = useState('')

	useEffect(() => {
		setCoordinatesXPosition(
			itemNpc.coordinates.replace('[', '').replace(']', '').split(',')[0]
		)
		setCoordinatesYPosition(
			itemNpc.coordinates.replace('[', '').replace(']', '').split(',')[1]
		)
	}, [])

	const onSubmitCoordinates = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		let formDataSceneNpcAppearance = new FormData()
		formDataSceneNpcAppearance.append(
			'coordinates',
			'[' +
				event.target.elements.coordinatesXPosition.value +
				',' +
				event.target.elements.coordinatesYPosition.value +
				']'
		)
		formDataSceneNpcAppearance.append(
			'sub_world_scene',
			itemNpc.id_scene_sub_world
		)
		try {
			let result_data_coordinates = await axiosSupreme(
				'patch',
				API_SUB_WORLD_SCENE_NPC_APPEARANCE +
					itemNpc.id_sub_world_scene_npc_appearance +
					'/',
				formDataSceneNpcAppearance
			)
			setTypeMessage('success')
			setMessage(t('success.action_success'))
			showAlert()
			setCoordinatesXPosition(
				result_data_coordinates.coordinates
					.replace('[', '')
					.replace(']', '')
					.split(',')[0]
			)
			setCoordinatesYPosition(
				result_data_coordinates.coordinates
					.replace('[', '')
					.replace(']', '')
					.split(',')[1]
			)
			setRefreshNpcsScene((prev) => !prev)
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t('errors.request_error'))
			if (errorPromise.response.status === 403) {
				setMessage(t('common.insufficient_permissions'))
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}

		setStateLoading(false)
	}

	return (
		<form onSubmit={onSubmitCoordinates}>
			<div className='npcSceneContent__container__Left__Form'>
				<label forhtml='inputCoordenadas__title'>
					{t('pages.editor.components.form_coordinates.coordinates')}
				</label>
				<div className='npcSceneContent__container__Left__Form__Columns'>
					<input
						maxLength='45'
						name='coordinatesXPosition'
						className='npcSceneContent__container__Left__Form__Columns__Input'
						id='coordinatesXPosition'
						type='text'
						placeholder={t(
							'pages.editor.components.form_coordinates.x_position'
						)}
						autoComplete='off'
						value={coordinatesXPosition}
						onChange={(event) =>
							onInputChangeDefault(event, setCoordinatesXPosition)
						}
						onKeyPress={(event) => handleKeyPressInteger(event)}
						required></input>
					<input
						maxLength='45'
						name='coordinatesYPosition'
						className='npcSceneContent__container__Left__Form__Columns__Input'
						id='coordinatesYPosition'
						type='text'
						placeholder={t(
							'pages.editor.components.form_coordinates.y_position'
						)}
						autoComplete='off'
						value={coordinatesYPosition}
						onChange={(event) =>
							onInputChangeDefault(event, setCoordinatesYPosition)
						}
						onKeyPress={(event) => handleKeyPressInteger(event)}
						required></input>
				</div>
				{stateLoading === false ? (
					<div className='npcSceneContent__container__Left__Form__Save'>
						<SaveButton></SaveButton>
					</div>
				) : (
					<LoadingIcon />
				)}
			</div>
		</form>
	)
}
