import { useActivity, useGameStatus } from '../../provider/ActivityProvider'
import '../../styles/Playground.scss'

import Anagram from '../games/Anagram'
import WordSeach from '../games/WordSearch'
import Hangman from '../games/Hangman'
import ContextPairs from '../games/ContextPairs'
import Memory from '../games/Memory'
import WordWithImage from '../games/WordWithImage'

import PlaygroundResults from '../fragments/PlaygroundResults'
import PlaygroundStart from '../fragments/PlaygroundStart'
import { useEffect, useRef, useState } from 'react'
import { useSoundAction } from '../../hooks/useSoundAction'

import anagramTutorial from '../../assets/videos/tutorials/anagram.mp4'
import anagramTutorialWebm from '../../assets/videos/tutorials/anagram.webm'
import contextPairsTutorial from '../../assets/videos/tutorials/contextPairs.mp4'
import contextPairsTutorialWebm from '../../assets/videos/tutorials/contextPairs.webm'
import hangmanTutorial from '../../assets/videos/tutorials/hangman.mp4'
import hangmanTutorialWebm from '../../assets/videos/tutorials/hangman.webm'
import crosswordTutorial from '../../assets/videos/tutorials/crossword.mp4'
import crosswordTutorialWebm from '../../assets/videos/tutorials/crossword.webm'
import wordWithImageTutorial from '../../assets/videos/tutorials/wordWithImage.mp4'
import wordWithImageTutorialWebm from '../../assets/videos/tutorials/wordWithImage.webm'
import wordSearchTutorial from '../../assets/videos/tutorials/wordSearch.mp4'
import wordSearchTutorialWebm from '../../assets/videos/tutorials/wordSearch.webm'
import memoryTutorial from '../../assets/videos/tutorials/memory.mp4'
import memoryTutorialWebm from '../../assets/videos/tutorials/memory.webm'
import Crossword from '../games/Crossword'
import { useTranslation } from 'react-i18next'

/**
 * Renders a list of words and the game that plays with that words.
 * @param {String} data.actualGameType The actual game of the NPC
 * @param {Objects} data.gameTypes all of the posible games
 * @returns
 */
export default function Playground({
	data,
	onClose,
	onTryAgain,
	onComplete,
	isNewTry,
}) {
	const { t } = useTranslation()
	const [gameStatus, , , starsEarned] = useGameStatus()
	const { wordsFragments } = useActivity()
	const { soundAction } = useSoundAction()

	const currentStars = useRef(starsEarned.current)

	useEffect(() => {
		return () => {
			window.scene.scene[
				currentStars.current !== starsEarned.current
					? 'restart'
					: 'resume'
			]()
		}
	}, [])

	useEffect(() => {
		if (wordsFragments.length > 0) {
			window.scene.scene.pause()
		}
	}, [wordsFragments])

	if (wordsFragments.length === 0) return null

	const games = {
		[data.gameTypes.anagram]: {
			title: t('pages.game.components.names_for_minigames.order_word'),
			component: Anagram,
			videoTutorial: anagramTutorial,
			videoTutorialWebm: anagramTutorialWebm,
		},
		[data.gameTypes.wordSearch]: {
			title: t('pages.game.components.names_for_minigames.search_word'),
			component: WordSeach,
			videoTutorial: wordSearchTutorial,
			videoTutorialWebm: wordSearchTutorialWebm,
		},
		[data.gameTypes.hangman]: {
			title: t('pages.game.components.names_for_minigames.choose_word'),
			component: Hangman,
			videoTutorial: hangmanTutorial,
			videoTutorialWebm: hangmanTutorialWebm,
		},
		[data.gameTypes.memory]: {
			title: t('pages.game.components.names_for_minigames.pairs_game'),
			component: Memory,
			videoTutorial: memoryTutorial,
			videoTutorialWebm: memoryTutorialWebm,
		},
		[data.gameTypes.contextPairs]: {
			title: t('pages.game.components.names_for_minigames.merge_word'),
			component: ContextPairs,
			videoTutorial: contextPairsTutorial,
			videoTutorialWebm: contextPairsTutorialWebm,
		},
		[data.gameTypes.crossword]: {
			title: t(
				'pages.game.components.names_for_minigames.crossword_word'
			),
			component: Crossword,
			videoTutorial: crosswordTutorial,
			videoTutorialWebm: crosswordTutorialWebm,
		},
		[data.gameTypes.wordWithImage]: {
			title: t(
				'pages.game.components.names_for_minigames.word_with_image'
			),
			component: WordWithImage,
			videoTutorial: wordWithImageTutorial,
			videoTutorialWebm: wordWithImageTutorialWebm,
		},
		notFound: {
			title: t('pages.game.components.names_for_minigames.not_found'),
			component: (
				<div style={{ color: 'red' }}>
					{t('pages.game.components.names_for_minigames.error')}
				</div>
			),
		},
	}
	let gameTitle = games[data.actualGameType]?.title

	let GameComponent = games[data.actualGameType]?.component
	let gameVideoTutorial = games[data.actualGameType]?.videoTutorial
	let gameVideoTutorialWebm = games[data.actualGameType]?.videoTutorialWebm

	if (!gameTitle) {
		gameTitle = games.notFound.title
		GameComponent = games.notFound.component
	}

	const handlerOnTryAgain = () => {
		onTryAgain()
		soundAction('game', null, 'replayAudio')
	}

	const handlerOnClose = () => {
		onClose()
		soundAction('game', null, 'removeAudio')
	}

	return (
		<div className='playground' id='playground'>
			<div className='playground__right'>
				<div className='playground__main'>
					<TopBar
						gameTitle={gameTitle}
						gameStatus={gameStatus}
						onClose={handlerOnClose}
					/>
					<GameContainer
						isNewTry={isNewTry}
						gameVideoTutorial={gameVideoTutorial}
						gameVideoTutorialWebm={gameVideoTutorialWebm}>
						<GameComponent wordsData={wordsFragments} />
					</GameContainer>
					{gameStatus !== 'inProgress' && (
						<PlaygroundResults
							onTryAgain={handlerOnTryAgain}
							onClose={handlerOnClose}
							onComplete={onComplete}
						/>
					)}
				</div>
			</div>
		</div>
	)
}

const TopBar = ({ gameTitle, gameStatus, onClose }) => {
	return (
		<div className='playground__main__bar'>
			<div className='playground__main__bar__title'>{gameTitle}</div>
			<div className='playground__main__bar__buttons'>
				<button
					className='playground__main__bar__buttons__close'
					onClick={() => {
						onClose(gameStatus)
					}}
				/>
			</div>
		</div>
	)
}

const GameContainer = ({
	isNewTry,
	gameVideoTutorial,
	gameVideoTutorialWebm,
	children,
}) => {
	const { soundAction } = useSoundAction()
	const [gameHasStarted, setGameHasStarted] = useState(isNewTry)
	const onStartGame = () => {
		soundAction('effect', 'TLIJ6', 'play')
		setGameHasStarted(true)
	}

	return (
		<section className='playground__main__game'>
			{gameHasStarted ? (
				<div className='playground__main__game__animation playground__main__game__animation__show'>
					{children}
				</div>
			) : (
				<PlaygroundStart
					src={gameVideoTutorial}
					srcWebm={gameVideoTutorialWebm}
					onClick={onStartGame}
				/>
			)}
		</section>
	)
}
